import actions from './actions';

export interface IDonation {
	amount: number,
	anonymous: string,
	paymentResponse: null|any,
	isLoading: any,
	paymentAlertState: null|any,
}

const initState: IDonation = {
	amount: 0,
	anonymous: '0',
	paymentResponse: {},
	isLoading: { status: false },
	paymentAlertState: { isVisible: false },
};

export default function donationReducer(state = initState, { type, payload }) {
	switch (type) {
		case actions.AMOUNT_SET: {
			return { ...state, amount: payload }
		}
		case actions.ANONYMOUS_SET: {
			return { ...state, anonymous: payload }
		}
		case actions.LOADING_SET: {
			return { ...state, isLoading: payload }
		}
		case actions.PAYMENT_RESPONSE_SET: {
			return { ...state, paymentResponse: payload }
		}
		case actions.PAYMENT_ALERT_SET: {
			return { ...state, paymentAlertState: payload }
		}
		default: {
			return state;
		}
	}
}
