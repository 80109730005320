import styled from 'styled-components';

import { Container, Col } from 'react-bootstrap';

export const StyledContainer = styled(Container)`
  padding: 30px 0;
  background-color: #F2F2F2;
`;

export const NavCol = styled(Col)`
  height: 200px;

  background-color: #ffffff;

  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 40px;

  padding: 36px 20px;

  & ul {
    padding: 0;
    list-style: none;

    & span {
      padding: 0 18px;
      color: #064a78;
      cursor: pointer;

      &.active {
        background-color: #A8AFCC;
        color: #ffffff;
      }
    }
  }
`;
