// 0 - just created donation, without any approval, before the communication with a payment provider
// 1 - payment is reserved on an escrow account
// 2 - cash in
// 3 - cash back
// 4 - cash back error
// 5 - canceled for some reason

// 6 - there is already the transaction Id, but payment isn't finished
// it's either in the process of filling the online paypal/giropay form (and waiting for a webhook)
// or 5-days period for giropay SEPA.

// 7 - pending SEPA donation becomes expired if no payment inside 5 days

// 15 - the payment is already received and almost paid out
// (received one approval from 2 required / waiting for disbursement or repayment)

export const VALID_STATUSES = [1, 2, 3, 4, 6, 15];
export const RECEIVED_CASH_STATUSES = [1, 2, 3, 4, 15];
export const PENDING_STATUS = [6];

export const FREE_MAX_PAYMENT = 1499;
export const MAX_PAYMENT = 150000;
export const PAYDIRECT_MAX_PAYMENT = 1000;
