import styled from 'styled-components';
import { Container, Image, Button } from 'react-bootstrap';

export const StyledContainer = styled(Container)`
  background-color: #2E4A7D;

  padding: 50px 0;

  background-image: url('/bg-dark.png');
  background-position: center bottom;
  background-repeat: no-repeat;

  @media screen and (max-width: 720px) {
    background-image: none;
  }
`;

export const DefaultContainer = styled(Container)`
  background-color: white;
  padding: 30px 60px;

  @media screen and (max-width: 720px) {
    background-image: none;
    padding: 10px;
  }
`;

export const StyledImage = styled(Image)`
  width: 280px;
  height: 280px;

  @media screen and (max-width: 720px) {
    width: 200px;
    height: 200px;
  }
`;

export const Avatar = styled(Image)`
  width: 80px;
  height: 80px;
`;

export const SubmitButton = styled(Button)`
  background: #003064 !important;
`;
