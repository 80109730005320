import actions from './actions';

export interface ISupporters {
	supporters: null|any,
}

const initState: ISupporters = {
	supporters: [],
};

export default function supportersReducer(state = initState, { type, payload }) {
	switch (type) {
		case actions.SUPPORTERS_SET: {
			return { supporters: payload }
		}
		default: {
			return state;
		}
	}
}
