import { STORAGE_KEYS } from '../constants/storage';

class StorageUtils {

	// Common
	static storeValue(key: string, value: any) {
		sessionStorage.setItem(key, JSON.stringify(value));
	}

	static restoreValue(key: string, defaultValue: any = null) {
		const value = sessionStorage.getItem(key);

		if (!value) return defaultValue;

		try {
			const parsedValue = JSON.parse(value);
			return parsedValue || defaultValue;
		} catch (e) {
			return defaultValue;
		}
	}

	static clearValue(key: string) {
		sessionStorage.removeItem(key);
	}

	static clearAll() {
		sessionStorage.clear();
	}

	// Auth
	static storeToken(token: string) {
		StorageUtils.storeValue(STORAGE_KEYS.token, token);
	}

	static restoreToken() {
		return StorageUtils.restoreValue(STORAGE_KEYS.token, '');
	}

	static clearToken() {
		return StorageUtils.clearValue(STORAGE_KEYS.token);
	}
}

export default StorageUtils;
export { StorageUtils };
