import { API_ROUTES } from '../constants/routes';
import { BaseService } from './BaseService';

class StaticPagesService extends BaseService {
  constructor() {
    super(API_ROUTES.root);
  }

  loadStaticPage = async (alias) => {
    const { agent } = this;

    try {
      return await agent.get(`/api/0.1${API_ROUTES.staticPages}/${alias}`);
    } catch (err) {
      return err.response;
    }
  };

  loadStaticPages = async () => {
    const { agent } = this;

    try {
      return await agent.get(`/api/0.1${API_ROUTES.staticPages}`);
    } catch (err) {
      return err.response;
    }
  };

  loadFaq = async () => {
    const { agent } = this;

    try {
      return await agent.get(`/api/0.1${API_ROUTES.faq}`);
    } catch (err) {
      return err.response;
    }
  };
}

const Service = new StaticPagesService();

export default StaticPagesService;
export { Service as StaticPagesService };
