import actions from './actions';

export interface IStaticPages {
	staticPage: null|any,
	staticPages: null|any,
	faq: null|any,
}

const initState: IStaticPages = {
	staticPage: {},
	staticPages: [],
	faq: [],
};

export default function supportersReducer(state = initState, { type, payload }) {
	switch (type) {
		case actions.STATIC_PAGE_SET: {
			return { ...state, staticPage: payload }
		}
		case actions.STATIC_PAGES_SET: {
			return { ...state, staticPages: payload }
		}
		case actions.FAQ_SET: {
			return { ...state, faq: payload }
		}
		default: {
			return state;
		}
	}
}
