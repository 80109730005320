import styled from 'styled-components';
import { Container, Form, Col } from 'react-bootstrap';

export const Image = styled.img`
  margin-right: 15px;
  height: 68px;

  @media screen and (max-width: 720px) {
    margin-top: 5px;
  }
`;

export const StyledContainer = styled(Container)`
  background-color: #2E4A7D;

  padding: 50px 0;

  background-image: url('/bg-dark.png');
  background-position: left bottom;
  background-repeat: no-repeat;

  @media screen and (max-width: 720px) {
    background-image: none;
  }
`;

export const DefaultContainer = styled(Container)`
  background-color: white;
  padding: 30px 60px;
  @media screen and (max-width: 720px) {
    background-image: none;
  }

  & .amount {
    padding-top: 1%;
    font-weight: 700;
  }

  & .form-label {
    font-size: 14px !important;
  }

  @media screen and (max-width: 720px) {
    padding: 10px;
  }
`;

export const InfoBlock = styled.div`
  background: #FFFFFF;
  font-size: 18px;
  font-weight: 700;
  padding-bottom: 15px;
`;

export const TableLabel = styled.div`
  font-size: 18px;
  font-weight: 700;
  padding: 2em 0;
`

export const SSLText = styled.div`
  font-weight: 700;
`

export const FormCheck = styled(Form.Check)`
  padding-top: 2em;
  font-size: 14px;
`
export const Description = styled.p`
  font-size: 14px !important;
`;

export const CheckDescription = styled.p`
  padding-top: 2em;
  font-size: 14px !important;
  margin-bottom: 0;
`;

export const SslCol = styled(Col)`
  .row {
    @media screen and (max-width: 720px) {
      margin-left: 5px;
      margin-right: 5px;
    }
  }
`

export const CurrencyCol = styled(Col)`
  margin-top: 7px;
`
