import styled from 'styled-components';
import projectsBgSrc from '../images/current_projects_bg.png';

export const Wrapper = styled.div`

  background: #455B8C;
  background-image: url(${projectsBgSrc});
  background-position: left bottom;
  background-repeat: no-repeat;

  min-height: 650px;

  padding: 80px 0;


  & h2 {
    font-weight: 600;
    line-height: 1em;
    font-size: 32px;
    color: #FFFFFF;

    margin-bottom: 0px;
  }
`;

export const ProjectsList = styled.div`
  @media screen and (max-width: 720px) {
    padding: 0;
  }
`;

export const MoreProjects = styled.div`
  align-self: center;
  cursor: pointer;

  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: #FFFFFF;

  background: #003064;
  border-radius: 22.5px;

  padding: 15px 55px;
  width: fit-content;
  margin: 30px auto 0 auto;
`;
