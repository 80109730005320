import { createSelector } from 'reselect';
import { IAuth } from './reducer';
interface IParams {
  Auth: IAuth
}

const user = ({ Auth }: IParams) => Auth.user;
const loginResponse = ({ Auth }: IParams) => Auth.loginResponse;
const signupResponse = ({ Auth }: IParams) => Auth.signupResponse;
const resetPasswordResponse = ({ Auth }: IParams) => Auth.resetPasswordResponse;
const resetPasswordConfirmResponse = ({ Auth }: IParams) => Auth.resetPasswordConfirmResponse;
const regConfirmResponse = ({ Auth }: IParams) => Auth.regConfirmResponse;
const updateUserResponse = ({ Auth }: IParams) => Auth.updateUserResponse;
const alertForUpdate = ({ Auth }: IParams) => Auth.alertForUpdate;

export const selectUser = createSelector([user], user => user);
export const selectLoginResponse = createSelector([loginResponse], loginResponse => loginResponse);
export const selectSignupResponse = createSelector([signupResponse], signupResponse => signupResponse);
export const selectResetPasswordResponse = createSelector(
  [resetPasswordResponse], resetPasswordResponse => resetPasswordResponse
);
export const selectResetPasswordConfirmResponse = createSelector(
  [resetPasswordConfirmResponse], resetPasswordConfirmResponse => resetPasswordConfirmResponse
);
export const selectRegConfirmResponse = createSelector([regConfirmResponse], regConfirmResponse => regConfirmResponse);
export const selectUpdateUserResponse = createSelector([updateUserResponse], updateUserResponse => updateUserResponse);
export const selectAlertForUpdate = createSelector([alertForUpdate], alertForUpdate => alertForUpdate);

export const selectIsLoggedIn = createSelector([selectUser], user => Boolean(user && user.email));
