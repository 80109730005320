import styled from 'styled-components';
import { Container } from 'react-bootstrap';

export const StyledContainer = styled(Container)`
  background-color: #2E4A7D;

  padding: 50px 0;

  background-image: url('/bg-dark.png');
  background-position: center bottom;
  background-repeat: no-repeat;

  @media screen and (max-width: 720px) {
    background-image: none;
  }
`;

export const DefaultContainer = styled(Container)`
  background-color: white;
  padding: 30px 60px;
  font-size: 14px;

  & button {
    font-size: 14px;
  }

  @media screen and (max-width: 720px) {
    background-image: none;
    padding: 10px;
  }
`;

export const Title = styled.h3`
  text-transform: none;
  font-size: 26px;
  line-height: 1.3;
`;
