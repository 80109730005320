import { API_ROUTES } from '../constants/routes';
import { BaseService } from './BaseService';

class ProjectService extends BaseService {
  constructor() {
    super(API_ROUTES.root);
  }

  loadProject = async (alias) => {
    const { agent } = this;

    try {
      return await agent.get(`/api/0.1${API_ROUTES.projects}/${alias}`);
    } catch (err) {
      return err.response;
    }
  };

  loadProjects = async (params) => {
    const { agent } = this;

    try {
      return await agent.get(`/api/0.1${API_ROUTES.projects}?order=desc`, { params });
    } catch (err) {
      return err.response;
    }
  };
}

const Service = new ProjectService();

export default ProjectService;
export { Service as ProjectService };
