import { useSelector } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { Redirect, useParams } from 'react-router-dom';

import styled from 'styled-components';

import ProjectHeader from '../../project-details/project-header';
import Stats from '../../project-details/stats';
import RenderRadios from '../content/radios'
import { Donation } from '../stats/donation';

import { selectIsLoggedIn } from '../../../redux/auth/selectors';

type Props = {};

const Image = styled.img`
  margin-right: 20px;

  @media screen and (max-width: 720px) {
    margin-top: 5px;
  }
`;

const StyledContainer = styled(Container)`
  background-color: #2E4A7D;

  padding: 50px 0;

  background-image: url('/bg-dark.png');
  background-position: center bottom;
  background-repeat: no-repeat;

  @media screen and (max-width: 720px) {
    background-image: none;
  }
`;

const DefaultContainer = styled(Container)`
  background-color: white;
  padding: 30px 60px;

  @media screen and (max-width: 720px) {
    background-image: none;
    padding: 10px;

    .container-fluid {
      padding-left: 0;
    }
  }
`;

const InfoBlock = styled.div`
  padding-left: 15px;
  background: #FFFFFF;
  font-size: 18px;
  font-weight: 700;
  padding-bottom: 15px;

  @media screen and (max-width: 720px) {
    padding-left: 0;
  }
`;

const SSLText = styled.div`
  font-weight: 700;
`

const SslCol = styled(Col)`
  .row {
    @media screen and (max-width: 720px) {
      margin-left: 5px;
      margin-right: 5px;
    }
  }
`

interface DonationParams {
  alias: string;
}

const PageDonation = (props: Props) => {
  const { alias } = useParams<DonationParams>();
  const isLoggedIn = useSelector(selectIsLoggedIn);

  if (isLoggedIn) {
    return <Redirect to={`/project/${alias}/donation/step2`} />;
  }

  return (
    <>
      <ProjectHeader />
      <Stats />
      <StyledContainer fluid>
        <DefaultContainer fluid='lg'>
          <Row>
            <Col md={{span: 8}}>
              <InfoBlock>{Donation.info_text}</InfoBlock>
              <RenderRadios/>
            </Col>
            <Col md={{span: 4}}>
              <Row>
                <Col md={{span: 4}}><Image src='/ssl.png'/></Col>
                <SslCol>
                  <Row><SSLText>{Donation.ssl_label}</SSLText></Row>
                  <Row>{Donation.ssl_text}</Row>
                </SslCol>
              </Row>
            </Col>
          </Row>
        </DefaultContainer>
      </StyledContainer>
    </>
  );
};

export default PageDonation;
