import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';

import Supporter from '../supporter';

import { selectSupporters } from '../../../../redux/supporters/selectors';
import { supportersActions } from '../../../../redux/supporters/actions';
import { selectProject } from '../../../../redux/project/selectors';

import { VALID_STATUSES } from '../../../../constants/payment-statuses'

type Props = {};

const Supporters = (props: Props) => {
  const dispatch = useDispatch();
  const { projectId } = useSelector(selectProject);
  const supporters = useSelector(selectSupporters);
  const donations = supporters.filter(d => VALID_STATUSES.includes(d.status) && d.isShowSepaPeriod);

  useEffect(() => {
    dispatch(supportersActions.loadSupporters(projectId));
  }, [dispatch, projectId]);

  return (
    <Row>
      <Col>
        <p className='h5 font-weight-bold'>Unterstützungen:</p>
        { donations.map(s => (<Supporter {...s}  key={s.projectDonationId} />) ) }
      </Col>
    </Row>
  );
};

export default Supporters;
