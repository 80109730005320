import { createSelector } from 'reselect';
import { IStaticPages } from './reducer';

interface IParams {
  StaticPages: IStaticPages,
}

const staticPage = ({ StaticPages }: IParams) => StaticPages.staticPage;
const staticPages = ({ StaticPages }: IParams) => StaticPages.staticPages;
const faq = ({ StaticPages }: IParams) => StaticPages.faq;

export const selectStaticPage = createSelector([staticPage], staticPage => staticPage);
export const selectStaticPages = createSelector([staticPages], staticPages => staticPages);
export const selectFaq = createSelector([faq], faq => faq);
