import styled from 'styled-components';

type Props ={
  vertical?: boolean,
  margin?: number
};

type StylesType = {
  margin?: number
};

const HorisontalDivider = styled.div<StylesType>`
  background-color: #e2e9ee;
  height: 1px;
  width: 100%;
  box-sizing: border-box;
  margin: ${({ margin }) => margin ? margin : 0}px 0px;
  flex: 0 0 auto;
`;

const VerticalDivider = styled(HorisontalDivider)<StylesType>`
  height: 100%;
  width: 1px;
  margin: 0px ${({ margin }) => margin ? margin : 0}px;
`;



const Divider = ({ vertical, margin = 10 }: Props) => {
  if (vertical) {
    return <VerticalDivider margin={margin} />
  }

  return <HorisontalDivider margin={margin} />
};

export default Divider;
