import styled from 'styled-components';

import { Container, Image } from 'react-bootstrap';

export const StyledContainer = styled(Container)`
  background-color: #ffffff;
  padding: 10px 30px 30px;
`;

export const StyledImage = styled(Image)`
  width: 188px;
  height: 188px;
`;

export const LinksList = styled.ul`
  list-style: none;
  padding: 0;

  & li {
    margin-bottom: 5px;
  }

  & a {
    margin-left: 5px;
  }
`;
