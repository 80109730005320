import actions from './actions';
import { projectCommentTypes } from '../../interfaces'

export interface IComment {
	comments: Array<projectCommentTypes>,
	loading: null|any,
}

const initState: IComment = {
	comments: [],
	loading: { comments: false },
};

export default function commentReducer(state = initState, { type, payload }) {
	switch (type) {
		case actions.COMMENTS_SET: {
			return { ...state, comments: payload }
		}
		case actions.COMMENT_ADD: {
			return { ...state, comments: [payload].concat(state.comments) }
		}
		case actions.COMMENTS_LOADING: {
			return { ...state, loading: { ...state.loading, ...payload } }
		}
		default: {
			return state;
		}
	}
}
