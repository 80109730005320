import styled from 'styled-components';
import CookieConsent from 'react-cookie-consent';

import AppHeader from './app-header';
import AppFooter from './app-footer';
import Routes from '../routes';
import AppLoader from '../components/loaders';
import CookieBanner from '../components/cookie-banner'

import { useSelector } from 'react-redux';
import { selectUiLoading } from '../redux/app/selectors';

const AppWrapper = styled.div`
  font-family: 'PT Sans', sans-serif;
`;

function App() {
  const { loading } = useSelector(selectUiLoading);

  return (
    <AppWrapper>
      <AppHeader />
      <main>
        <Routes />
      </main>
      <AppFooter />
      <AppLoader visible={loading} />
      <CookieConsent
        location='bottom'
        disableStyles={true}
        buttonText='Akzeptieren'
        cookieName='Notwendig'
        buttonClasses='btn btn-primary btn-block cb-btn'
        containerClasses='cookie-banner'
        contentClasses='cb-content'
        buttonWrapperClasses='cb-buttons-wrapper'
      >
        <CookieBanner />
      </CookieConsent>
    </AppWrapper>
  );
};

export default App;
