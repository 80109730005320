import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { authActions } from '../../redux/auth/actions';
import { selectResetPasswordConfirmResponse } from '../../redux/auth/selectors';
import * as S from './styles';

interface ParamTypes {
  key: string;
}

const ResetPassword = () => {
  const { key } = useParams<ParamTypes>();

  const initValues = {
    password: '',
    repeatPassword: '',
    token: key
  };

  const [payload, setPayload] = useState(initValues);

  const resetPasswordConfirmResponse = useSelector(selectResetPasswordConfirmResponse);

  const dispatch = useDispatch();

  const error = resetPasswordConfirmResponse?.data?.detail;

  const changeInputHandler = (event) => {
    setPayload({ ...payload, [event.target.name]: event.target.value });
  }

  const onSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    dispatch(authActions.resetpasswordConfirm(payload));
  };

  const renderForm = () => (
    <Form onSubmit={onSubmit}>
      <Form.Group>
        <Form.Label>Passwort *</Form.Label>
        <Form.Control isInvalid={error} required type='password' name='password' onChange={changeInputHandler} />
        <Form.Control.Feedback type='invalid'>{error}</Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>Passwort wiederholen *</Form.Label>
        <Form.Control isInvalid={error} required type='password' name='repeatPassword' onChange={changeInputHandler} />
        <Form.Control.Feedback type='invalid'>{error}</Form.Control.Feedback>
      </Form.Group>
      <Button type='submit' className='btn-login btn btn-danger' disabled={false}>
        Passwort zurücksetzen
      </Button>
    </Form>
  )

  const renderCheckEmail = () => (
    <span>
      Bitte prüfe deinen E-Mail-Eingang. Du hast eine E-Mail mit einem Link erhalten, über den du dein Passwort	neu festlegen kannst.
    </span>
  )

  return (
    <S.Wrapper>
      <S.StyledContainer fluid='lg'>
        <S.Content>
          <div className='mt-4 mb-4 ml-md-5 mr-md-5 mh-100'>
            <Row className='d-flex justify-content-center'>
              <Col className='pb-2 pt-2 pl-2 pr-2'>
                <h3>Passwort zurücksetzen</h3>
                { key ? renderForm() : renderCheckEmail() }
              </Col>
            </Row>
          </div>
        </S.Content>
      </S.StyledContainer>
    </S.Wrapper>
  );
};

export default ResetPassword;
