import * as S from './styles';

interface Props {
  main?: number;
  recent?: number;
  source?: string;
};

const ProgressBar = ({ main = 0, recent = 0, source = 'stats' }: Props) => {
  return (
    <S.ProgressContainer id='progressBar' source={source}>
      <S.ProgressMain progress={main} />
      <S.ProgressExtra progress={recent} />
    </S.ProgressContainer>
  );
};

export default ProgressBar;
