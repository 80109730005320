import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { projectDonationBidsTypes } from 'interfaces';

import { selectProject, selectLoading } from 'redux/project/selectors';
import { convertToEuro } from 'utils/helpers';

import * as S from './styles';

const ProjectDonationBids = () => {
  const loading = useSelector(selectLoading);
  const { projectDonationBids, alias, isFinished } = useSelector(selectProject);
  const donationUrl = `/project/${alias}/donation/step1`;
  const isDisabledButton = isFinished || loading.project;

  if (!projectDonationBids?.length) return null;

  return (
    <S.InfoBlock>
      <S.InfoBlockHeader>Das kann Ihr Beitrag bewegen:</S.InfoBlockHeader>
        <S.BidWrapper>
          {projectDonationBids.map((b: projectDonationBidsTypes) => <S.Bid key={b.id}>
            <S.Amount>{convertToEuro(b.amount)}</S.Amount>
            <S.Description dangerouslySetInnerHTML={{ __html: b.description }} />
            <Link to={isDisabledButton ? '#' : donationUrl} className={isDisabledButton ? 'disabled-link' : ''}>
              <S.Button>Projekt jetzt unterstützen!</S.Button>
            </Link>
          </S.Bid>)}
        </S.BidWrapper>
    </S.InfoBlock>
  )
}

export default ProjectDonationBids
