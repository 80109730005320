import { createSelector } from 'reselect';
import { IDonation } from './reducer';

interface IParams {
  Donation: IDonation
}

const amount = ({ Donation }: IParams) => Donation.amount;
const anonymous = ({ Donation }: IParams) => Donation.anonymous;
const isLoading = ({ Donation }: IParams) => Donation.isLoading;
const paymentResponse = ({ Donation }: IParams) => Donation.paymentResponse;
const paymentAlertState = ({ Donation }: IParams) => Donation.paymentAlertState;

export const selectAmount = createSelector([amount], amount => amount);
export const selectAnonymous = createSelector([anonymous], anonymous => anonymous);
export const selectLoading = createSelector([isLoading], isLoading => isLoading);
export const selectPaymentResponse = createSelector([paymentResponse], paymentResponse => paymentResponse);
export const selectPaymentAlertState = createSelector([paymentAlertState], paymentAlertState => paymentAlertState);
