import { Container, Row, Col } from 'react-bootstrap';
import Login from './login';
import Registration from './registration';
import * as S from './styles';

type Props = {};

const Auth = (props: Props) => {
  return (
    <S.StyledContainer fluid>
      <Container fluid='lg'>
        <Row className='mb-3 d-none d-sm-flex'>
          <Col sm={6}>
            <h2>Bitte einloggen:</h2>
          </Col>
          <Col sm={6}>
            <h2>Neu hier?<br />Bitte registrieren Sie sich:</h2>
          </Col>
        </Row>
        <S.StyledRow>
          <Col sm={5}>
            <h2 className='mb-3 d-block d-sm-none'>Bitte einloggen:</h2>
            <Login />
          </Col>
          <Col sm={{ span: 5, offset: 1 }}>
            <h2 className='mb-3 d-block d-sm-none'>Neu hier?<br />Bitte registrieren Sie sich:</h2>
            <Registration />
          </Col>
        </S.StyledRow>
      </Container>
    </S.StyledContainer>
  )
}

export default Auth;
