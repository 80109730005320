import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { BsChevronDoubleRight } from 'react-icons/bs';
import { useMount } from 'react-use';

import * as S from './styles';

import Description from './description';
import Comments from './comments';
import Supporters from './supporters';
import ProjectStarter from './project-starter';
import ProjectDonationBids from './project-donation-bids';

import { selectIsLoggedIn } from 'redux/auth/selectors';
import { selectProject, selectLoading } from 'redux/project/selectors';
import { selectComments } from 'redux/project-comments/selectors';
import { donationActions } from 'redux/donation/actions';
import { commentActions } from 'redux/project-comments/actions';

import { useQueryParam } from 'utils/helpers';
import { VALID_STATUSES, FREE_MAX_PAYMENT, MAX_PAYMENT } from 'constants/payment-statuses';

type Props = {};

const TABS = ['description', 'comments', 'supporters']

const DetailContent = (props: Props) => {
  const queryTab = useQueryParam().get('tab') || 'description';
  const initTab = TABS.includes(queryTab) ? queryTab : 'description';
  const [activeTab, setActiveTab] = useState(initTab);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const loading = useSelector(selectLoading);
  const { alias, donationReceipts, projectDonations, title, isFinished, projectId } = useSelector(selectProject);
  const comments = useSelector(selectComments);
  const dispatch = useDispatch();
  const history = useHistory();
  const mailToBody = `Ich möchte zu Projekt ${title} (${window.location.href}) folgenden Verstoß melden:`;
  const encodedMailToBody = encodeURIComponent(mailToBody);
  const encodedMailToSubject = encodeURIComponent('Meldung eines Verstoßes');

  const donationCount = projectDonations.filter(d => VALID_STATUSES.includes(d.status) && d.isShowSepaPeriod).length;

  const handleChangeAmount = (event) => {
    event.target.setCustomValidity('');

    const value = event.target.value;
    const valueToUpdate = parseInt(value, 10) || '0';

    if (valueToUpdate < 1) {
      event.target.setCustomValidity('Der Mindestbetrag einer Spende liegt bei einem Euro.')
    }

    dispatch(donationActions.setAmount(valueToUpdate))
  }

  useMount(() => {
    dispatch(donationActions.setAmount('0'));
  });

  useEffect(() => {
    if (projectId) dispatch(commentActions.loadComments(projectId));
  }, [dispatch, projectId]);

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    history.push(`/project/${alias}/donation/step${ isLoggedIn ? 2 : 1 }`);
  }

  const handleValidate = (event) => {
    const value = parseInt(event.target.value, 10) || 0;

    if (!isLoggedIn && value > FREE_MAX_PAYMENT) {
      event.target.setCustomValidity(`Anonymes Spenden ist nur bei Beträgen unter ${FREE_MAX_PAYMENT + 1} € möglich. Bitte melden Sie sich für höhere Spendenbeträge an (zur Anmeldung/Registrierung s. Menüleiste oben rechts).`);
    } else if (isLoggedIn && value > MAX_PAYMENT) {
      event.target.setCustomValidity(`Der maximale Spendenbetrag liegt bei ${MAX_PAYMENT} Euro.`);
    } else {
      event.target.setCustomValidity('Der Mindestbetrag einer Spende liegt bei einem Euro.');
    }
  };

  const renderTab = () => {
    switch (activeTab) {
      case 'description': return <Description />;
      case 'comments': return <Comments />;
      case 'supporters': return <Supporters />;
      default: return null;
    }
  }

  const renderDonationReceiptLabel = () => (
    <S.DonateReceipt><span className='icon-spendenquittung' /> Spendenbescheinigung möglich</S.DonateReceipt>
  )

  return (
    <S.StyledContainer fluid>
      <Container fluid='lg'>
        <Row className='mr-0 ml-0'>
          <S.StyledProjectCol sm={8}>
            <S.StyledTabRow>
              <Col sm={4}>
                <S.TabButton
                  className={activeTab === 'description' ? 'active' : ''}
                  onClick={() => setActiveTab('description')}>
                  Projektbeschreibung
                </S.TabButton>
              </Col>
              <Col sm={4}>
                <S.TabButton
                  className={activeTab === 'comments' ? 'active' : ''}
                  onClick={() => setActiveTab('comments')}>
                  Pinnwand <S.Badge>{comments.filter(c => !c.isDeleted).length}</S.Badge>
                </S.TabButton>
              </Col>
              <Col sm={4}>
                <S.TabButton
                  className={activeTab === 'supporters' ? 'active' : ''}
                  onClick={() => setActiveTab('supporters')}>
                  Unterstützungen <S.Badge>{donationCount}</S.Badge>
                </S.TabButton>
              </Col>
            </S.StyledTabRow>
            { renderTab() }
          </S.StyledProjectCol>
          <Col sm={4}>
            <S.DonateBlock>
              <S.DonateHeader>Spender werden</S.DonateHeader>
              <Form onSubmit={handleSubmit}>
                <Form.Group>
                  <Form.Control
                    type='number'
                    min={1}
                    max={isLoggedIn ? MAX_PAYMENT : FREE_MAX_PAYMENT}
                    step={1}
                    onChange={handleChangeAmount}
                    name='amount'
                    onInvalid={handleValidate}
                  />
                  <span className='amount'>EUR</span>
                </Form.Group>
                <Button variant='primary' type='submit' block={true} disabled={isFinished || loading.project}>
                  Projekt jetzt unterstützen!
                </Button>
              </Form>
              { donationReceipts && renderDonationReceiptLabel() }
            </S.DonateBlock>
            <ProjectStarter />
            <ProjectDonationBids />
            <S.MailToLink
              href={`mailto:info%40wir-bewalden.sh?subject=${encodedMailToSubject}&body=${encodedMailToBody}%0A`}
            >
              <BsChevronDoubleRight /> Verstoß melden
            </S.MailToLink>
          </Col>
        </Row>
      </Container>
    </S.StyledContainer>
  );
};

export default DetailContent;
