import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Row, Col, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import ParentComment from './parent-comment';

import * as S from './styles';

import { UI_ROUTES } from 'constants/routes';
import { selectComments, selectLoading } from 'redux/project-comments/selectors';
import { selectProject } from 'redux/project/selectors';
import { selectUser } from 'redux/auth/selectors';
import { commentActions } from 'redux/project-comments/actions';

type Props = {};

const Comments = (props: Props) => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const comments = useSelector(selectComments);
  const project = useSelector(selectProject);
  const { comments: isLoading } = useSelector(selectLoading);

  const isLoggedIn = Boolean(user && user.email);

  const [text, setText] = useState('');

  const changeInputHandler = (event) => {
    setText(event.target.value);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    dispatch(commentActions.createComment({ text, projectId: project.projectId }));
    setText('');
  };

  const unregisteredMessage = <S.UnregisteredWrapper>
    Damit Sie ein Projekt kommentieren können, müssen Sie sich <Link to={UI_ROUTES.register}>
      einloggen oder registrieren
    </Link>.
  </S.UnregisteredWrapper>

  return (
    <Row>
      <Col>
        <S.Title>Schreiben Sie einen Kommentar:</S.Title>
        { !isLoggedIn && unregisteredMessage }
        <S.FormWrapper>
          <Form onSubmit={onSubmit} className='mt-1'>
            <Form.Control
              name='text'
              as='textarea'
              rows={8}
              onChange={changeInputHandler}
              value={text}
              placeholder='Ihr Kommentar'
              required
              maxLength={3000}
              disabled={!isLoggedIn}
            />
            <S.SubmitButton className='col-lg-6' variant='primary' type='submit' disabled={!isLoggedIn || isLoading}>
              Kommentar abschicken
            </S.SubmitButton>
          </Form>
        </S.FormWrapper>
        { comments.filter(c => !c.parentId).map(c => (<ParentComment {...c} key={c.projectCommentId} />)) }
      </Col>
    </Row>
  );
};


export default Comments;
