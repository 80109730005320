const DOMAINS = {
  DEV: 'wir-bewalden-frontend-dev.tableofvisions.com',
  STAGE: 'stage.wir-bewalden.sh',
  PROD: 'wir-bewalden.sh',
};

const servers = {
  DEV: {
    url: `https://${DOMAINS.DEV}`,
    cors_domain: '*',
    API_URL: {
      url: 'https://wir-bewalden-backend-dev.tableofvisions.com',
    },
    credentials: {
      client_secret: 'IBQh2uvAPo8Rg',
      client_id: 'ibshdev01'
    },
  },
  STAGE: {
    url: `https://${DOMAINS.STAGE}`,
    cors_domain: '*',
    API_URL: {
      url: 'https://stage.wir-bewegen.sh',
    },
    credentials: {
      client_secret: '82be92ca3882be92ca38',
      client_id: '82be92ca38'
    },
  },
  PROD: {
    url: `https://${DOMAINS.PROD}`,
    cors_domain: '*',
    API_URL: {
      url: 'https://www.wir-bewegen.sh',
    },
    credentials: {
      client_secret: '3dbc1ca17fde13bade98',
      client_id: '98bc1ca17fde'
    },
  },
};

const getServerByHost = (hostname) => {
  const domain = Object.entries(DOMAINS).filter((i) => hostname.includes(i[1]))[0];
  const settings = domain ? servers[domain[0]] : servers.DEV;

  return settings;
};

const getServerByEnv = (env_name) => {
  const domain = Object.entries(DOMAINS).filter(
    (i) => String(i[0]) === env_name
  )[0];
  const settings = domain ? servers[domain[0]] : servers.DEV;

  return settings;
};

const isProduction = () => process.env.NODE_ENV === 'production';
const buildServers = () => servers;

export { isProduction, buildServers, getServerByHost, getServerByEnv }
