import styled from 'styled-components';

import { Container, Image } from 'react-bootstrap';

export const StyledContainer = styled(Container)`
  background-color: #ffffff;
  padding: 10px 30px 30px;
`;

export const StyledImage = styled(Image)`
  width: 188px;
  height: 188px;
`;

export const AddItemWrapper = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #2E4A7D;
  cursor: pointer;
  margin-top: 3px;
`;

export const RemoveItemWrapper = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #D4004B;
  cursor: pointer;
`;

export const AvatarDescriptionWrapper = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #1A1A18;
`;
