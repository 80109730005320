import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';

import ProjectHeader from '../../project-details/project-header';
import Stats from '../../project-details/stats';

import * as S from './styles';

interface DonationParams {
  alias: string;
}

type Props = {};

const ProjectDonationCancel = (props: Props) => {
  const { alias } = useParams<DonationParams>();

  return (
    <>
      <ProjectHeader />
      <Stats />

      <S.StyledContainer fluid>
        <S.DefaultContainer fluid='lg'>
          <Row>
            <Col>
              <S.Title>Sie haben den Spendenprozess abgebrochen</S.Title>
            </Col>
          </Row>
          <Row>
            <Col>
              Bitte führen Sie den Spendenprozess erneut durch. Sollten Sie Fragen zum Bezahlvorgang oder wiederholt
              Probleme im Spendenprozess haben, wenden Sie sich bitte an uns über das Kontaktformular.
            </Col>
          </Row>
          <Row className='mt-4'>
            <Col>
              <Link to={`/project/${alias}`} className='mr-2'>
                <Button variant='primary'>Zurück zum Projekt</Button>
              </Link>
              <Link to='/'>
                <Button variant='primary'>Zur Startseite</Button>
              </Link>
            </Col>
          </Row>
        </S.DefaultContainer>
      </S.StyledContainer>
    </>
  );
};

export default ProjectDonationCancel;
