import styled from 'styled-components';
import { Container } from 'react-bootstrap';

export const Image = styled.img`
  margin-right: 20px;

  @media screen and (max-width: 720px) {
    width: 100%;
    margin-right: 0;
  }
`;

export const StyledContainer = styled(Container)`
  background-color: #2E4A7D;

  padding: 50px 0;

  background-image: url('/bg-dark.png');
  background-position: center bottom;
  background-repeat: no-repeat;

  @media screen and (max-width: 720px) {
    background-image: none;
  }
`;

export const DefaultContainer = styled(Container)`
  background-color: white;
  padding: 30px 60px;

  @media screen and (max-width: 720px) {
    background-image: none;
    padding: 10px;
  }
`;

export const InfoBlock = styled.div`
  background: #FFFFFF;
  font-size: 18px;
  font-weight: 700;
  padding-bottom: 15px;
`;

export const Text = styled.p`
  font-size: 14px;
`;
