import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { selectAmount, selectLoading } from 'redux/donation/selectors';

interface Props {};

const CollapseBankCheck = (props: Props) => {
  const amount = useSelector(selectAmount);
  const { status } = useSelector(selectLoading);

  return (
    <div id='example-collapse-text2'>
      <Button variant='primary' type='submit' disabled={status}>Jetzt {amount}-Euro-Spende zusagen</Button>
    </div>
  )
}

export default CollapseBankCheck
