import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { BsChevronDoubleRight } from 'react-icons/bs';

import { selectProject } from '../../../../redux/project/selectors';

import * as S from './styles';

type Props = {};

const ProjectStarter = (props: Props) => {
  const { user, alias } = useSelector(selectProject);
  const profileUrl = `/profile/${user?.uid}?alias=${alias}`;

  return (
    <S.InfoBlock>
      <S.InfoBlockHeader>Projektstarter</S.InfoBlockHeader>
        <Row>
          <Col>
            <Link to={profileUrl}>
              <S.Avatar src={ user?.avatar || '/person.svg' } roundedCircle />
            </Link>
          </Col>
          <Col>
            <S.Name><Link to={profileUrl}>{ user?.firstName + ' ' + user?.lastName }</Link></S.Name>
            <S.Link><Link to={profileUrl}><BsChevronDoubleRight /> zum Profil</Link></S.Link>
          </Col>
        </Row>
    </S.InfoBlock>
  )
}

export default ProjectStarter
