import { createSelector } from 'reselect';
import { IHomepage } from './reducer';

interface IParams {
  Homepage: IHomepage
}

const homepageBlocks = ({ Homepage }: IParams) => Homepage.homepageBlocks;
const homepageSections = ({ Homepage }: IParams) => Homepage.homepageSections;
const loading = ({ Homepage }: IParams) => Homepage.loading;

export const selectHomepageBlocks = createSelector([homepageBlocks], (homepageBlocks) => homepageBlocks);
export const selectHomepageSections = createSelector([homepageSections], (homepageSections) => homepageSections);
export const selectLoading = createSelector([loading], (loading) => loading);
