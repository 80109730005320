import { Card, Col } from 'react-bootstrap';
import { homepageBlockTypes } from '../../../interfaces'

import * as S from './styles';

interface Props {
  block: homepageBlockTypes
};

const BlockCard = ({ block: { image, title, description, link } }: Props) => (
  <Col xs={12} sm={4} className='mb-3'>
    <S.StyledCard>
      <a href={link}><Card.Img variant='top' src={image} /></a>
      <Card.Body>
        <S.TitleLink href={link}><Card.Title><h6>{ title }</h6></Card.Title></S.TitleLink>
        <Card.Text>
          <S.Description>{ description }</S.Description>
        </Card.Text>
        <S.Link><a href={link}>Details</a></S.Link>
      </Card.Body>
    </S.StyledCard>
  </Col>
);

export default BlockCard;
