import { makeActionCreator } from 'utils/ReduxUtils';

const prefix = '[Profile]';

const TYPES = {
	PROFILE_LOAD: `${prefix} profile`,
	PROFILE_SET: `${prefix} profile_set`,
	PROFILE_LOADING: `${prefix} profile_loading`,
};

const actions = {
	...TYPES,
	loadProfile: makeActionCreator(TYPES.PROFILE_LOAD),
	setProfile: makeActionCreator(TYPES.PROFILE_SET),
	profileLoading: makeActionCreator(TYPES.PROFILE_LOADING),
};

export default actions;
export { actions as profileActions };
