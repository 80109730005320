import { useSelector, useDispatch } from 'react-redux';
import { useMount } from 'react-use';
import { Row } from 'react-bootstrap';

import { selectHomepageBlocks, selectLoading } from '../../../redux/home-page/selectors';
import homepageActions from '../../../redux/home-page/actions';

import BlockCard from './card'
import Loader from '../../../components/loaders/local-loader'

import * as S from './styles';

const Info = () => {
  const dispatch = useDispatch();
  const homePageBlocks = useSelector(selectHomepageBlocks);
  const loading = useSelector(selectLoading);

  useMount(() => {
    dispatch(homepageActions.loadHomepageBlocks());
  });

  const renderCards = () => (
    homePageBlocks.slice(0, 3).map(block => <BlockCard block={block} key={block.blockId} />)
  )

  const renderContent = () => (
    homePageBlocks.length ? renderCards() : <h3>Keine Treffer</h3>
  )

  return (
    <S.StyledContainer fluid='lg'>
      <Row>
        { loading.homePage? <Loader /> : renderContent() }
      </Row>
    </S.StyledContainer>
  );
};

export default Info;
