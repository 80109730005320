import { Card, Col } from 'react-bootstrap';
import { homepageSectionTypes } from '../../../interfaces'

import * as S from './styles';

interface Props {
  section: homepageSectionTypes
};

const SectionCard = ({ section: { image, title, description } }: Props) => (
  <Col xs={12} sm={4} className='mb-3'>
    <S.StyledCard>
      <Card.Img variant='top' src={image} />
      <Card.Body>
        <Card.Title><h6>{ title }</h6></Card.Title>
        <Card.Text>
          <S.Description>{ description }</S.Description>
        </Card.Text>
      </Card.Body>
    </S.StyledCard>
  </Col>
);

export default SectionCard;
