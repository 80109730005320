import { Container, Row, Col } from 'react-bootstrap';
import { MapContainer, TileLayer } from 'react-leaflet'
import { useSelector } from 'react-redux';
import { selectProjects, selectFinishedProjects } from '../../../redux/project/selectors';

import ProjectMarker from './project-marker';

import 'leaflet/dist/leaflet.css';
import * as S from './styles';

const START_POINT: [number, number] = [54.2054818, 9.8];

interface IProps {};

const Map = (props: IProps) => {
  const { _embedded: { projects } } = useSelector(selectProjects);
  const finishedProjects = useSelector(selectFinishedProjects);
  const allProjects = finishedProjects._embedded.projects.concat(projects);

  return (
    <S.StyledContainer fluid>
      <Container fluid='lg'>
        <Row className='mb-4'>
          <Col>
            <h2>Projekte in ihrer Nähe</h2>
          </Col>
        </Row>
        <Row className='mb-4'>
          <Col>
            <MapContainer
              center={START_POINT}
              zoom={8}
              scrollWheelZoom={true}
              style={{ height: '510px', width: '100%' }}
            >
              <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              { allProjects && allProjects.map(p => (
                (p.latitude && p.longitude) ? <ProjectMarker {...p} key={p.projectId} /> : null
              )) }
            </MapContainer>
          </Col>
        </Row>
        <Row>
          <S.LegendCol className='d-flex flex-sm-row flex-column'>
            <div className='mr-3 mb-3 mb-sm-0'><img src='/marker-running.png' />Laufende Projekte</div>
            <div><img src='/marker-finished.png' />Beendete Projekte</div>
          </S.LegendCol>
        </Row>
      </Container>
    </S.StyledContainer>
  );
};

export default Map;
