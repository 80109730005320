import ProjectHeader from './project-header';
import Stats from './stats';
import Content from './content';

const PageDetails = () => {
  window.scrollTo(0, 0);

  return (
    <>
      <ProjectHeader />
      <Stats />
      <Content />
    </>
  )
}

export default PageDetails;
