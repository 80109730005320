import styled from 'styled-components';
import { Container, Image } from 'react-bootstrap';

export const StyledLogoContainer = styled(Container)`
  padding-top: 15px;
`;

export const StyledMenuContainer = styled(Container)`
  background-color: #003064;

  & .navbar {
    padding: 0;

    @media screen and (max-width: 576px) {
      width: 100%;
    }

    & svg {
      font-size: xxx-large;
    }
  }

  & .nav-link {
    padding: 8px 20px !important;
  }

  & a {
    color: #ffffff !important;
    text-decoration: none !important;
    font-size: 14px;
    font-weight: 400;

    @media screen and (max-width: 575px) {
      font-size: 20px;
    }
  }

  & .active {
    background-color: #D4004B;
  }
`;

export const MainLogo = styled.img`
  width: 401px;

  @media screen and (max-width: 720px) {
    width: 215px;
    position: relative;
    top: 0;
  }
`;

export const Avatar = styled(Image)`
  max-width: 20px;
  max-height: 20px;
  margin-right: 10px;
  position: relative;
  top: -2px;
`;
