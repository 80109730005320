import { useSelector, useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { useEffect } from 'react';
import { getServerByHost } from '../../config';

import { selectStaticPage } from '../../redux/static-pages/selectors';
import { staticPagesActions } from '../../redux/static-pages/actions';

import Loader from '../../components/loaders/local-loader'

import * as S from './styles';

const StaticPages = (props) => {
  const alias = props.location.pathname.split('/')[2];
  const dispatch = useDispatch();
  const staticPage = useSelector(selectStaticPage);
  const hostname = window.location.hostname;
  const newSrc = `src="${getServerByHost(hostname).API_URL.url}/`;
  const modifiedContent = staticPage.content?.replaceAll('src="/', newSrc)

  useEffect(() => {
    dispatch(staticPagesActions.loadStaticPage(alias));
  }, [dispatch, alias]);

  useEffect(() => {
    if (window.location.hash) {
      setTimeout(function () {
          var hash = window.location.hash;
          window.location.hash = "";
          window.location.hash = hash;
      }, 1000);
    }
  }, []);

  const pageText = (
    <S.PageContentContainer fluid='lg' className='mt-5 mb-5 p-sm-5 bg-white'>
      <Row>
        <Col>
          <S.Title>{staticPage.titleIntern}</S.Title>
          <S.PageContent dangerouslySetInnerHTML={{ __html: modifiedContent }} />
        </Col>
      </Row>
    </S.PageContentContainer>
  );

  const noFoundText = (
    <S.EmptyContainer fluid='lg'>
      <Row>
        <Col>
          <h2>Seite nicht gefunden (404)</h2>
          <h4 className='mt-4'>
            Bitte überprüfe, ob du die richtige URL eingegeben hast und versuche es erneut.
          </h4>
        </Col>
      </Row>
    </S.EmptyContainer>
  );

  const renderContent = () => {
    switch(staticPage.responseStatus) {
      case 200:
        return pageText;
      case 404:
        return noFoundText;
      default:
        return <S.EmptyContainer fluid='lg'><Loader /></S.EmptyContainer>;
    }
  };

  return (
    <S.Wrapper fluid>
      { renderContent() }
    </S.Wrapper>
  )
};

export default StaticPages;
