import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, Collapse, Col, Row } from 'react-bootstrap';

import { authActions } from '../../../../redux/auth/actions';
import { selectSignupResponse } from '../../../../redux/auth/selectors';

import TermsLabel from '../../../../components/terms-label'

import { checkError } from '../../../../utils/helpers'

import styled from 'styled-components';

const BackLink = styled.a`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.444444px;
  color: #064a78;
`;

const initValues = {
  title: '',
  firstName: '',
  lastName: '',
  username: '',
  email: '',
  password: '',
  repeatPassword: '',
  userType: 2
};

type Props = {
  open: boolean
};

const Registration = ({ open }: Props) => {
  const [payload, setPayload] = useState(initValues);
  const dispatch = useDispatch();

  const signupResponse = useSelector(selectSignupResponse);

  const errors = signupResponse?.data?.validation_messages;

  const changeInputHandler = (event) => {
    setPayload({ ...payload, [event.target.name]: event.target.value });
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    dispatch(authActions.register(payload));
  };

  return (
    <Collapse in={open}>
      <div id='example-collapse-text2'>
        <Form onSubmit={handleSubmit}>
          <Form.Group className='mb-3'>
            <Form.Label>Titel</Form.Label>
            <Form.Control isInvalid={errors?.title} type='text' name='title' onChange={changeInputHandler} />
            <Form.Control.Feedback type='invalid'>{errors?.title}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className='mb-3'>
            <Form.Label>Vorname *</Form.Label>
            <Form.Control
              isInvalid={errors?.firstName}
              required
              type='text'
              name='firstName'
              onChange={changeInputHandler}
            />
            <Form.Control.Feedback type='invalid'>{errors?.firstName}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className='mb-3'>
            <Form.Label>Name *</Form.Label>
            <Form.Control
              isInvalid={errors?.lastName}
              required
              type='text'
              name='lastName'
              onChange={changeInputHandler}
            />
            <Form.Control.Feedback type='invalid'>{errors?.lastName}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className='mb-3'>
            <Form.Label>Benutzername *</Form.Label>
            <Form.Control
              isInvalid={errors?.username}
              required
              type='text'
              name='username'
              onChange={changeInputHandler}
            />
            <Form.Control.Feedback type='invalid'>{errors?.username}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className='mb-3'>
            <Form.Label>E-Mail-Adresse *</Form.Label>
            <Form.Control isInvalid={errors?.email} required type='email' name='email' onChange={changeInputHandler} />
            <Form.Control.Feedback type='invalid'>{ checkError(errors?.email, 'email') }</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className='mb-3'>
            <Form.Label>Passwort *</Form.Label>
            <Form.Control
              isInvalid={errors?.password}
              required
              type='password'
              name='password'
              onChange={changeInputHandler}
            />
            <Form.Control.Feedback type='invalid'>{errors?.password}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className='mb-3'>
            <Form.Label>Passwort wiederholen *</Form.Label>
            <Form.Control
              isInvalid={errors?.repeatPassword}
              required
              type='password'
              name='repeatPassword'
              onChange={changeInputHandler}
            />
            <Form.Control.Feedback type='invalid'>
              { checkError(errors?.repeatPassword, 'repeatPassword') }
            </Form.Control.Feedback>
          </Form.Group>

          <Row>
            <Col>
              <BackLink href='/faq' target='_blank'>{'>> Wann ist ein Passwort sicher? '}</BackLink>
            </Col>
          </Row>

          <Form.Group className='mb-3'>
            <Form.Check type='checkbox' label={<TermsLabel />} required/>
          </Form.Group>

          <Button variant='primary' type='submit'>
            Registrieren
          </Button>
        </Form>
      </div>
    </Collapse>
  )
}

export default Registration;
