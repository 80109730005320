import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, Collapse } from 'react-bootstrap';

import { authActions } from '../../../../redux/auth/actions';
import { selectLoginResponse } from '../../../../redux/auth/selectors';

const initValues = {
  username: '',
  password: '',
  grant_type: 'password'
};

type Props = {
  open: boolean
};

const Login = ({ open }: Props) => {
  const [payload, setPayload] = useState(initValues);
  const loginResponse = useSelector(selectLoginResponse);
  const dispatch = useDispatch();

  const hasError = loginResponse?.status === 401;

  const changeInputHandler = (event) => {
    setPayload({ ...payload, [event.target.name]: event.target.value });
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    dispatch(authActions.login(payload));
  };

  return (
    <Collapse in={open}>
      <div id='example-collapse-text1'>
        <Form onSubmit={handleSubmit}>
          <Form.Group className='mb-3'>
            <Form.Label>E-Mail-Adresse *</Form.Label>
            <Form.Control isInvalid={hasError} required type='email' name='username' onChange={changeInputHandler} />
          </Form.Group>

          <Form.Group className='mb-3'>
            <Form.Label>Passwort *</Form.Label>
            <Form.Control isInvalid={hasError} required type='password' name='password' onChange={changeInputHandler} />
            {hasError && <Form.Control.Feedback type='invalid'>E-Mail-Adresse oder Passwort nicht korrekt.</Form.Control.Feedback>}
          </Form.Group>

          <Form.Group className='mb-3'>
            <Form.Check type='checkbox' label='Eingeloggt bleiben' />
          </Form.Group>

          <Button variant='primary' type='submit'>
            Einloggen
          </Button>
        </Form>
      </div>
    </Collapse>
  )
}

export default Login;
