import mainTeaser from '../images/forest.png';
import { Row, Col } from 'react-bootstrap';
import * as S from './styles';

interface IProps {};

const Forest = (props: IProps) => {
  return (
    <>
      <S.ImageContainer fluid>
        <Row>
          <Col>
            <img src={ mainTeaser } alt='Wald' />
          </Col>
        </Row>
      </S.ImageContainer>
      <S.DescriptionContainer fluid='lg' className='text-center'>
        <Row className='mb-3'>
          <Col>
            <h1>WIR BEWALDEN SCHLESWIG-HOLSTEIN</h1>
          </Col>
        </Row>
        <Row className='justify-content-center'>
          <Col lg={8}>
            Unser Wald ist natürliche Ressource und magischer Sehnsuchtsort. Und wichtig fürs Klima.
            Denn Wald bindet CO2 und verlangsamt die Erderwärmung.
            Die Waldfläche Schleswig-Holsteins soll daher wachsen. Sie können helfen: Baum für Baum, Spende für Spende.
            Entdecken Sie unsere Aufforstungsprojekte!
          </Col>
        </Row>
      </S.DescriptionContainer>
    </>
  );
};

export default Forest;
