import axios from 'axios';

import { buildServers, getServerByHost, isProduction } from '../config';

const servers = buildServers();

const hostname = window.location.hostname;
const settings = getServerByHost(hostname);

const envOptions = isProduction() ? {} : {
  auth: {
    username: 'ibshdev01',
    password: 'IBQh2uvAPo8Rg'
  }
};
const agentOptions = Object.assign({}, { baseURL: settings.API_URL.url }, envOptions);

const agent = axios.create(agentOptions);

const userAgent = axios.create({
  baseURL: settings.API_URL.url,
});

export { agent, userAgent, servers, settings };
