import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useMount } from 'react-use';

import { Container, Row, Col, Nav, Navbar } from 'react-bootstrap';

import * as S from './styles';

import logoSrc from '../images/logo.svg';

import { BsList, BsX } from 'react-icons/bs';

import { selectUser } from '../../redux/auth/selectors';
import { useDispatch } from 'react-redux';
import { appActions } from '../../redux/app/actions';
import {Avatar} from './styles';

import { UI_ROUTES } from '../../constants/routes';

const AppHeader = () => {
  const [navExpanded, setNavExpanded] = useState(false);
  const dispatch = useDispatch();

  const user = useSelector(selectUser);
  const isLoggedIn = Boolean(user && user.email);

  useMount(() => {
    dispatch(appActions.appStart());
  });

  return (
    <>
      <S.StyledLogoContainer fluid='lg'>
        <Row>
          <Col><Link to='/'><S.MainLogo src={logoSrc} /></Link></Col>
        </Row>
      </S.StyledLogoContainer>
      <S.StyledMenuContainer fluid>
        <Container fluid='lg'>
          <Row className='justify-content-sm-end'>
            <Navbar expand='sm' onToggle={setNavExpanded}>
              <Navbar.Toggle aria-controls='basic-navbar-nav'  className='ml-auto border-0' >
                { navExpanded ? <BsX color='#ffffff'/> : <BsList color='#ffffff'/> }
              </Navbar.Toggle>
              <Navbar.Collapse id='basic-navbar-nav'>
                <Nav>
                  <Nav.Link as='span'><Link to={UI_ROUTES.darumGehts}>Darum geht&apos;s</Link></Nav.Link>
                  <Nav.Link as='span'><Link to={UI_ROUTES.partner}>Partner</Link></Nav.Link>
                  <Nav.Link as='span'><Link to='/faq'>FAQ</Link></Nav.Link>
                  <Nav.Link as='span' active={true} className='text-center'>
                    <a className='font-weight-bold' href='/#projects'>SPENDEN</a>
                  </Nav.Link>
                  { isLoggedIn ?
                    <Nav.Link as='span'>
                      <Link to='/profile'>
                        <Avatar src={user?.avatar || '/person.svg'} roundedCircle />
                        { user.username }
                      </Link>
                    </Nav.Link> :
                    <Nav.Link as='span'><Link to='/auth'>Login</Link></Nav.Link>
                  }
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </Row>
        </Container>
      </S.StyledMenuContainer>
    </>
  );
};

export default AppHeader;
