import actions from './actions';

export interface IAuth {
	user: null|any,
	loginResponse: null|any,
	signupResponse: null|any,
	resetPasswordResponse: null|any,
	resetPasswordConfirmResponse: null|any,
	regConfirmResponse: null|any,
	updateUserResponse: null|any,
	alertForUpdate: null|any,
}

const initState: IAuth = {
	user: {},
	loginResponse: null,
	signupResponse: null,
	resetPasswordResponse: null,
	resetPasswordConfirmResponse: null,
	regConfirmResponse: null,
	updateUserResponse: null,
	alertForUpdate: { isVisible: false },
};

export default function authReducer(state = initState, { type, payload }) {
	switch (type) {
		case actions.PROFILE_REFRESH: {
			return { ...state, user: payload }
		}
		case actions.LOGIN_RESPONSE_SET: {
			return { ...state, loginResponse: payload }
		}
		case actions.SIGNUP_RESPONSE_SET: {
			return { ...state, signupResponse: payload }
		}
		case actions.RESETPASSWORD_RESPONSE_SET: {
			return { ...state, resetPasswordResponse: payload }
		}
		case actions.RESETPASSWORD_CONFIRM_RESPONSE_SET: {
			return { ...state, resetPasswordConfirmResponse: payload }
		}
		case actions.REG_CONFIRM_RESPONSE_SET: {
			return { ...state, regConfirmResponse: payload }
		}
		case actions.UPDATE_USER_RESPONSE_SET: {
			return { ...state, updateUserResponse: payload }
		}
		case actions.ALERT_FOR_UPDATE: {
			return { ...state, alertForUpdate: payload }
		}
		default: {
			return state;
		}
	}
}
