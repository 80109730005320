import { all, fork } from 'redux-saga/effects';

import appSaga from './app/saga';
import authSaga from './auth/saga';
import donationSaga from './donation/saga';
import homepageSaga from './home-page/saga';
import supportersSaga from './supporters/saga';
import projectSaga from './project/saga';
import profileSaga from './profile/saga';
import staticPagesSaga from './static-pages/saga';
import commentSaga from './project-comments/saga';

export default function* rootSaga() {
  yield all([
    fork(appSaga),
    fork(authSaga),
    fork(donationSaga),
    fork(homepageSaga),
    fork(supportersSaga),
    fork(projectSaga),
    fork(profileSaga),
    fork(staticPagesSaga),
    fork(commentSaga),
  ]);
}
