import { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Row } from 'react-bootstrap';
import { BsChevronDoubleRight } from 'react-icons/bs';

import Comment from './comment';
import DeletedComment from './deleted-comment';

import { selectLoading, selectComments } from 'redux/project-comments/selectors';
import { selectProject } from 'redux/project/selectors';
import { selectUser } from 'redux/auth/selectors';
import { commentActions } from 'redux/project-comments/actions';

import * as S from './styles';
import { projectCommentTypes } from 'interfaces';

const ParentComment = (props: projectCommentTypes) => {
  const dispatch = useDispatch();
  const formRef = useRef<HTMLInputElement>(null);
  const { comments: isLoading } = useSelector(selectLoading);
  const user = useSelector(selectUser);
  const project = useSelector(selectProject);
  const comments = useSelector(selectComments);
  const isLoggedIn = Boolean(user && user.email);

  const [isOpenReplyForm, setIsOpenReplyForm] = useState(false);
  const [subText, setSubText] = useState('');

  const { projectCommentId, isDeleted, isInitiator } = props;
  const subComments = comments.filter(c => c.parentId === projectCommentId).reverse();

  useEffect(() => {
    if (isOpenReplyForm && formRef?.current) formRef.current.scrollIntoView({ behavior: 'smooth' });
  }, [isOpenReplyForm]);

  const changeInputHandler = (event) => {
    setSubText(event.target.value);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    dispatch(commentActions.createComment({ text: subText, parentId: projectCommentId, projectId: project.projectId }));
    setSubText('');
  };

  const handleOpenForm = () => setIsOpenReplyForm(true);

  const handleCloseForm = () => {
    setIsOpenReplyForm(false);
    setSubText('');
  };

  const renderForm = () => (
    <>
      <S.Title>Schreiben Sie einen Antwort:</S.Title>
      <S.FormWrapper>
        <Form onSubmit={onSubmit} className='mt-1'>
          <Form.Control
            name='text'
            as='textarea'
            rows={8}
            value={subText}
            onChange={changeInputHandler}
            placeholder='Ihr Kommentar'
            required
            maxLength={3000}
            disabled={!isLoggedIn}
          />
          <Row>
            <div className='col-lg-6'>
            <S.SubmitButton variant='primary' type='submit' disabled={!isLoggedIn || isLoading}>
              Antwort abschicken
            </S.SubmitButton>
            </div>
            <S.CancelButton className='col-lg-6' onClick={handleCloseForm}>
              <BsChevronDoubleRight /> Abbrechen
            </S.CancelButton>
          </Row>
        </Form>
      </S.FormWrapper>
    </>
  );

  const renderSubComment = (comment: projectCommentTypes) => {
    if (comment.isDeleted) return <DeletedComment className='ml-5' key={comment.projectCommentId} />;

    return <S.Wrapper className='ml-5 pb-0' key={comment.projectCommentId} isInitiator={isInitiator}>
      <Comment {...comment} />
    </S.Wrapper>
  };

  if (isDeleted) return <DeletedComment />;

  return (
    <>
      <S.Wrapper className='d-flex flex-column' isInitiator={isInitiator}>
        <Comment {...props} key={projectCommentId} />
        { !isOpenReplyForm && <S.ReplyButton onClick={handleOpenForm}>Antworten</S.ReplyButton> }
      </S.Wrapper>
      { subComments.map(renderSubComment) }
      <div ref={formRef}>
        { isOpenReplyForm && renderForm() }
      </div>
    </>
  )
};

export default ParentComment;
