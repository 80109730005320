import styled from 'styled-components';

import { Container, Col } from 'react-bootstrap';

export const StyledContainer = styled(Container)`
  background: #455B8C;

  padding: 80px 0;

  @media screen and (max-width: 720px) {
    padding: 63px 0;
  }

  & h2 {
    font-weight: 600;
    line-height: 1em;
    font-size: 32px;
    color: #FFFFFF;

    margin-bottom: 15px;
  }
`;

export const MapImage = styled.div`
  width: 100%;
  height: 617px;
  margin-bottom: 20px;
  background-image: url('/karte.png');
  background-position: center center;
  background-repeat: no-repeat;
`;

export const LegendCol = styled(Col)`
  > div {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF;

    @media screen and (max-width: 720px) {
      margin: 0;
    }

    > img {
      height: 25px;
      margin-right: 7px;
    }
  }
`;
