import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col } from 'react-bootstrap';
import ImageGallery from 'react-image-gallery';

import { selectProject, selectLoading } from 'redux/project/selectors';

import Divider from 'components/divider';
import BlockData from './block-data';
import defaultPicture from './images/keine_project_img_big.png';

import * as S from '../styles';

type Props = {};

const Description = (props: Props) => {
  const loading = useSelector(selectLoading);
  const {
    alias,
    description,
    presentSupporting,
    presentCurSupport,
    presentImplement,
    projectLinks,
    imageBig,
    imageSmall,
    imageSecondary2,
    imageSecondary3,
    imageSecondary4,
    imageSecondary5,
    isFinished
  } = useSelector(selectProject);

  const projPictures = [
    imageBig, imageSmall, imageSecondary2, imageSecondary3, imageSecondary4, imageSecondary5
  ].filter(Boolean);
  const pictures = projPictures.length ? projPictures : [ defaultPicture ];
  const isPresentLinks = !!projectLinks?.length && (projectLinks.findIndex(l => l.link.trim()) >= 0);

  const renderImages = () => (
    <S.StyledImagesRow>
      <Col>
        <ImageGallery
          items={pictures.map((url) => ({ original: url || '', thumbnail: url || '' }))}
          showFullscreenButton={false}
          showPlayButton={false}
        />
      </Col>
    </S.StyledImagesRow>
  );

  const renderDonateButton = () => (
    <S.SupportButton type='button'>Projekt jetzt unterstützen!</S.SupportButton>
  );

  return (
    <>
      { renderImages() }
      <BlockData header='Worum geht es in diesem Projekt?' description={description} eventKey='0' />
      <BlockData header='Warum sollte dieses Projekt unterstützt werden?' description={presentSupporting} eventKey='1' />
      <BlockData header='Wie wird das Projekt umgesetzt?' description={presentCurSupport} eventKey='2' />
      <BlockData header='Wer steht hinter diesem Projekt?' description={presentImplement} eventKey='3' />
      <S.ProjectLinkRow>
        { isPresentLinks && <S.StyledTextBlockCol>
          <S.BlockHeader>Links zum Projekt:</S.BlockHeader>
          { projectLinks.map(l => <div key={l.id}><S.ProjectLink href={l.link}>{l.link}</S.ProjectLink></div>) }
          <Divider margin={20} />
        </S.StyledTextBlockCol> }
      </S.ProjectLinkRow>
      {/* <S.SocialsRow className='align-items-center'>
        <Col sm='auto' className='mr-2'>
          <S.BlockHeader>Weitersagen:</S.BlockHeader>
        </Col>
        <Col>
          <img className='mr-2' src='/facebook.svg'></img>
          <img className='mr-2' src='/twitter.svg'></img>
        </Col>
        <Col className='text-right'>
          <a href='#'>{'> Diesen Link teilen'}</a>
        </Col>
      </S.SocialsRow> */}
      <S.SupportRow>
        <S.StyledTextBlockCol className='text-center'>
          <Link to={`/project/${alias}/donation/step1`}>
            { !isFinished && !loading.project && renderDonateButton() }
          </Link>
        </S.StyledTextBlockCol>
      </S.SupportRow>
    </>
  );
};

export default Description;
