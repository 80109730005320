import { all, takeLatest, put, call } from 'redux-saga/effects';
import { HomepageService } from '../../services/HomepageService';

import actions from './actions';

function* loadHomepageBlocks() {
  yield put(actions.homepageBlocksLoading({ homePage: true }));

  const response = yield call(HomepageService.getHomepageBlocks);

  if (response?.status === 200) {
    yield put(actions.setHomepageBlocks(response.data));
  }

  yield put(actions.homepageBlocksLoading({ homePage: false }));
}

function* loadHomepageSections() {
  yield put(actions.homepageSectionsLoading({ sections: true }));

  const response = yield call(HomepageService.getHomepageSections);

  if (response?.status === 200) {
    yield put(actions.setHomepageSections(response.data));
  }

  yield put(actions.homepageSectionsLoading({ sections: false }));
}

export default function* homepageSaga() {
  yield all([
    takeLatest(actions.HOMEPAGE_BLOCKS_LOAD, loadHomepageBlocks),
    takeLatest(actions.HOMEPAGE_SECTIONS_LOAD, loadHomepageSections)
  ]);
}
