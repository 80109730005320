import { makeActionCreator } from '../../utils/ReduxUtils';

const prefix = '[Comment]';

const TYPES = {
	COMMENTS_LOAD: `${prefix} comments`,
	COMMENTS_SET: `${prefix} comments_set`,
	COMMENTS_LOADING: `${prefix} comments_loading`,
	COMMENT_CREATE: `${prefix} comment_create`,
	COMMENT_ADD: `${prefix} comment_add`,
};

const actions = {
	...TYPES,
	loadComments: makeActionCreator(TYPES.COMMENTS_LOAD),
	createComment: makeActionCreator(TYPES.COMMENT_CREATE),
	setComments: makeActionCreator(TYPES.COMMENTS_SET),
	addComment: makeActionCreator(TYPES.COMMENT_ADD),
	commentsLoading: makeActionCreator(TYPES.COMMENTS_LOADING),
};

export default actions;
export { actions as commentActions };
