import { makeActionCreator } from '../../utils/ReduxUtils';

const prefix = '[Supporters]';

const TYPES = {
	SUPPORTERS_LOAD: `${prefix} supporters`,
	SUPPORTERS_SET: `${prefix} supporters_set`,
};

const actions = {
	...TYPES,
	loadSupporters: makeActionCreator(TYPES.SUPPORTERS_LOAD),
	setSupporters: makeActionCreator(TYPES.SUPPORTERS_SET),
};

export default actions;
export { actions as supportersActions };
