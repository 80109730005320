import { createSelector } from 'reselect';
import { ISupporters } from './reducer';

interface IParams {
  Supporters: ISupporters
}

const supporters = ({ Supporters }: IParams) => Supporters.supporters;

export const selectSupporters = createSelector([supporters], supporters => supporters);
