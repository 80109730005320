import actions from './actions';

export interface IApp {
	uiLoading: null|any,
}

const initState: IApp = {
	uiLoading: { loading: false },
};

export default function appReducer(state = initState, { type, payload }) {
	switch (type) {
		case actions.UI_LOADING: {
			return { uiLoading: payload }
		}
		default: {
			return state;
		}
	}
}
