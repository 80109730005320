import styled from 'styled-components';

export const InfoBlock = styled.div`
  padding: 30px;
  background: #FFFFFF;
  margin: 0 -15px 20px 5px;

  @media screen and (max-width: 575px) {
    margin: 20px -15px;
  }
`;

export const InfoBlockHeader = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 1em;
  margin: 0 0 20px 0;
  color: #003064;
  text-align: center;
`;

export const BidWrapper = styled.ul`
  list-style-type: none;
  margin: 20px 0 -20px;
  padding: 0;

  .disabled-link {
    pointer-events: none;
    background-color: #003064 !important;
    opacity: 0.65;
  }
`;

export const Bid = styled.li`
  border-top: 1px solid #d9e0e8;
  margin: 0;
  padding: 30px 0;
  font-size: 16px;
  list-style-type: none;

  a {
    text-decoration-line: unset;
  }
`;

export const Amount = styled.div`
  font-size: 20px;
  font-weight: 700;
  width: 90px;
  height: 90px;
  line-height: 90px;
  margin: 0 auto 20px;
  background-color: #00a2ab;
  border-radius: 50%;
  color: #fff;
  text-align: center;
`;

export const Description = styled.p`
  margin-bottom: 20px;
  word-break: break-word;
`;

export const Button = styled.div`
  background-color: #003064;
  color: #fff;
  border-radius: 2px;
  border: 1px solid transparent;
  font-weight: bold;
  font-size: 14px;
  padding: 5px 15px;
  text-align: center;
  vertical-align: middle;

  &:hover {
    background-color: #D4004B;
  }
`;
