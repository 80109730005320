import { all, takeLatest, put, call } from 'redux-saga/effects';

import { ProfileService } from 'services/ProfileService';
import actions from './actions';

function* loadProfile(data) {
  const { payload } = data;

  yield put(actions.profileLoading({ profile: true }));

  const response = yield call(ProfileService.loadProfile, payload);

  if (response?.status === 200) {
    yield put(actions.setProfile(response.data));
  }

  yield put(actions.profileLoading({ profile: false }));
}

export default function* profileSaga() {
  yield all([
    takeLatest(actions.PROFILE_LOAD, loadProfile),
  ]);
}
