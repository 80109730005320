import styled from 'styled-components';

type ProgressType = {
  progress: string | number
};

type SourceType = {
  source: string;
};

export const ProgressContainer = styled.div<SourceType>`
  border-radius: ${props => props.source === 'stats' ? 21 : 15}px;
  height: ${props => props.source === 'stats' ? 42 : 30}px;
  overflow: hidden;
  width: 100%;
  background-color: #EDEDED;
  display: flex;
  margin-bottom: 12px;
`;

export const ProgressMain = styled.div<ProgressType>`
  background-color: #00A2AB;
  height: 100%;
  width: ${props => props.progress}%;
`;

export const ProgressExtra = styled.div<ProgressType>`
  background-color: #66b7b8;
  height: 100%;
  width: ${props => props.progress}%;
`;
