import { Container, Row, Col } from 'react-bootstrap';
import { BsLink45Deg } from 'react-icons/bs';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useMount } from 'react-use';
import { selectProfile } from 'redux/profile/selectors';
import { profileActions } from 'redux/profile/actions';

import * as S from './styles';

interface DonationParams {
  userId: string;
}

type Props = {};

const UserProfile = (props: Props) => {
  const { userId } = useParams<DonationParams>();
  const dispatch = useDispatch();
  const profile = useSelector(selectProfile);

  useMount(() => {
    dispatch(profileActions.loadProfile(userId));
  });

  const renderLinks = () => (
    <>
      <Row><Col><h6>Links</h6></Col></Row>
      <Row>
        <Col>
          <S.LinksList>
            { profile?.userLinks.map(l => <li key={l.link}><BsLink45Deg /><a href={l.link}>{l.link}</a></li>) }
          </S.LinksList>
        </Col>
      </Row>
    </>
  );

  return (
    <S.StyledContainer fluid>
      <Container fluid='lg'>
        <Row className='mb-5'>
          <Col>
            <S.ProfileContainer>
              <h2>{ profile?.firstName + ' ' + profile?.lastName }</h2>
              <strong>{profile?.city}</strong>
            </S.ProfileContainer>
          </Col>
        </Row>
        <Row>
          <Col>
            <S.ProfileContainer>
              <Row>
                <Col sm={12} md={4}>
                  <S.StyledImage src={ profile?.avatar || '/person.svg' } roundedCircle />
                </Col>
                <Col sm={12} md={4}>
                  <Row>
                    <Col>
                      <p className='h5'>
                        <strong>Über mich</strong>
                      </p>
                    </Col>
                  </Row>
                  <Row className='mb-3'>
                    <S.ColAbout>{profile?.about}</S.ColAbout>
                  </Row>
                </Col>
                <S.StatisticCol md={4}>
                  <Row><Col><h6>Statistik</h6></Col></Row>
                  <Row>
                    <Col>
                      <S.LinksList>
                        <li><strong>{ profile?.stats?.project_started || 0 }</strong> Project(e) gestartet</li>
                        <li><strong>{ profile?.stats?.project_followed || 0 }</strong> Project(e) beobachtet</li>
                        <li><strong>{ profile?.stats?.project_supported || 0 }</strong> Project(e) unterstützt</li>
                      </S.LinksList>
                    </Col>
                  </Row>
                  { !!profile?.userLinks?.length && renderLinks() }
                </S.StatisticCol>
              </Row>
            </S.ProfileContainer>
          </Col>
        </Row>
      </Container>
    </S.StyledContainer>
  )
}

export default UserProfile;
