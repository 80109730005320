import { Col, Form, FormGroup, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { selectPaymentResponse } from 'redux/donation/selectors';

const FormRow = styled(Row)`
  padding-top: 1em;
`

interface ReceiptProps {
  receiptData: any;
  setReceiptData: (values) => void;
};

const CollapseCheckboxForm = ({ receiptData, setReceiptData }: ReceiptProps) => {
  const changeInputHandler = (event) => {
    setReceiptData({ ...receiptData, [event.target.name]: event.target.value });
  }

  const paymentResponse = useSelector(selectPaymentResponse);
  const error = paymentResponse?.data?.message?.payment_option?.donation_receipt?.donationReceiptEmail;

  return (
    <div id='checkbox-form'>
      <FormGroup>
        <FormRow>
          <Col>
            <Form.Label>Vorname * </Form.Label>
            <Form.Control
              type='text'
              name='donationReceiptFirstname'
              required
              defaultValue={receiptData.donationReceiptFirstname}
              onChange={changeInputHandler}
            />
            <Form.Control.Feedback type='invalid'>
              Please provide a name
            </Form.Control.Feedback>
          </Col>
          <Col>
            <Form.Label>Nachname * </Form.Label>
            <Form.Control
              type='text'
              name='donationReceiptLastname'
              required
              defaultValue={receiptData.donationReceiptLastname}
              onChange={changeInputHandler}
            />
            <Form.Control.Feedback type='invalid'>
              Please provide a name
            </Form.Control.Feedback>
          </Col>
        </FormRow>
        <FormRow>
          <Col md={{span: 6}}>
            <Form.Label>Adresszusatz </Form.Label>
            <Form.Control
              type='text'
              name='donationReceiptAdditionalAddressInformation'
              defaultValue={receiptData.donationReceiptAdditionalAddressInformation}
              onChange={changeInputHandler}
            />
          </Col>
          <Col md={{span: 4}}>
            <Form.Label>Straße * </Form.Label>
            <Form.Control
              type='text'
              name='donationReceiptStreet'
              required
              defaultValue={receiptData.donationReceiptStreet}
              onChange={changeInputHandler}
            />
            <Form.Control.Feedback type='invalid'>
              Please provide a street
            </Form.Control.Feedback>
          </Col>
          <Col md={{span: 2}}>
            <Form.Label>Hausnummer * </Form.Label>
            <Form.Control
              type='text'
              name='donationReceiptHouse'
              required
              defaultValue={receiptData.donationReceiptHouse}
              onChange={changeInputHandler}
            />
            <Form.Control.Feedback type='invalid'>
              Please provide a house number
            </Form.Control.Feedback>
          </Col>
        </FormRow>
        <FormRow>
          <Col md={{span: 2}}>
            <Form.Label>PLZ * </Form.Label>
            <Form.Control
              type='text'
              name='donationReceiptZip'
              required
              defaultValue={receiptData.donationReceiptZip}
              onChange={changeInputHandler}
            />
            <Form.Control.Feedback type='invalid'>
              Please provide a PLZ
            </Form.Control.Feedback>
          </Col>
          <Col md={{span: 4}}>
            <Form.Label>Stadt * </Form.Label>
            <Form.Control
              type='text'
              name='donationReceiptCity'
              required
              defaultValue={receiptData.donationReceiptCity}
              onChange={changeInputHandler}
            />
            <Form.Control.Feedback type='invalid'>
              Please provide a name
            </Form.Control.Feedback>
          </Col>
          <Col md={{span: 6}}>
            <Form.Label>Land * </Form.Label>
            <Form.Control
              type='text'
              name='donationReceiptCountry'
              required
              defaultValue={receiptData.donationReceiptCountry}
              onChange={changeInputHandler}
            />
            <Form.Control.Feedback type='invalid'>
              Please provide a name
            </Form.Control.Feedback>
          </Col>
        </FormRow>
        <FormRow>
          <Col md={{span: 6}}>
            <Form.Label>E-Mail *</Form.Label>
            <Form.Control
              type='email'
              name='donationReceiptEmail'
              required
              isInvalid={error}
              defaultValue={receiptData.donationReceiptEmail}
              onChange={changeInputHandler}
            />
            <Form.Control.Feedback type='invalid'>
              Please provide a valid email
            </Form.Control.Feedback>
          </Col>
        </FormRow>
      </FormGroup>
    </div>
  )
};

export default CollapseCheckboxForm;
