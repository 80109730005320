import { API_ROUTES } from '../constants/routes';
import { BaseService } from './BaseService';

class CommentService extends BaseService {
  constructor() {
    super(API_ROUTES.root);
  }

  loadComments = async (id) => {
    const { agent } = this;

    try {
      return await agent.get(`/api/0.1${API_ROUTES.projectComments}?project_id=${id}`);
    } catch (err) {
      return err.response;
    }
  };

  createComment = async (payload) => {
    const { userAgent } = this;

    try {
      return await userAgent.post(`/api/0.1${API_ROUTES.projectComments}`, payload);
    } catch (err) {
      return err.response;
    }
  };

  deleteComment = async (id) => {
    const { agent } = this;

    try {
      return await agent.delete(`/api/0.1${API_ROUTES.projectComments}/${id}`);
    } catch (err) {
      return err.response;
    }
  };
}

const Service = new CommentService();

export default CommentService;
export { Service as CommentService };
