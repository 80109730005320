import * as S from './styles';

type Props = {
  className?: string,
  key?: number
};

const DeletedComment = (props: Props) => (
  <S.DeletedCommentWrapper className={props.className} key={props.key}>
    Dieser Kommentar wurde vom Administrator gelöscht.
  </S.DeletedCommentWrapper>
);

export default DeletedComment;
