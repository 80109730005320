import React from 'react';
import { Col, Button } from 'react-bootstrap';
import * as S from './styles';

type Props = {};

const FaqPage = (props: Props) => {
  return (
    <>
      <S.ContentContainerWithBg fluid>
        <S.ContentContainer fluid='lg'>
          <S.CustomRow>
            <S.TopCol lg={12}>
              <S.ErrorWrapper>
                <h1>
                  404 Error
                  <br/>
                  <small>Seite nicht gefunden!</small>
                </h1>
                <p>Wir haben zwar überall danach gesucht, konnten Ihre gewünschte Seite aber leider nirgends finden.</p>
              </S.ErrorWrapper>
              <S.Img src='/404.png' />
            </S.TopCol>
          </S.CustomRow>
          <S.CustomRow>
            <Col lg={12}>
              <S.ButtonsTitle>Vielleicht werden Sie hier fündig:</S.ButtonsTitle>
            </Col>
          </S.CustomRow>
          <S.CustomRow>
            <Col md={4} sm={6}>
              <a href='/#projects'><Button variant='primary'>Projekt unterstützen</Button></a>
            </Col>
            <Col md={4} sm={6}>
              <a href='/'><Button variant='primary'>Startseite</Button></a>
            </Col>
          </S.CustomRow>
        </S.ContentContainer>
      </S.ContentContainerWithBg>
    </>
  )
}

export default FaqPage;
