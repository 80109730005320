import { useSelector, useDispatch } from 'react-redux';
import { useMount } from 'react-use';
import { Row } from 'react-bootstrap';

import { selectHomepageSections, selectLoading } from '../../../redux/home-page/selectors';
import homepageActions from '../../../redux/home-page/actions';

import SectionCard from './card'
import Loader from '../../../components/loaders/local-loader'

import * as S from './styles';

const Quotes = () => {
  const dispatch = useDispatch();
  const homePageSections = useSelector(selectHomepageSections);
  const loading = useSelector(selectLoading);

  useMount(() => {
    dispatch(homepageActions.loadHomepageSections());
  });

  const renderCards = () => (
    homePageSections.slice(0, 3).map(section => <SectionCard section={section} key={section.sectionId} />)
  )

  const renderContent = () => (
    homePageSections.length ? renderCards() : <h3>Keine Treffer</h3>
  )

  return (
    <S.Wrapper>
      <S.StyledContainer fluid='lg'>
        <Row>
          { loading.sections? <Loader /> : renderContent() }
        </Row>
      </S.StyledContainer>
    </S.Wrapper>
  );
};

export default Quotes;
