import { makeActionCreator } from '../../utils/ReduxUtils';

const prefix = '[Project]';

const TYPES = {
	PROJECT_LOAD: `${prefix} project`,
	PROJECT_SET: `${prefix} project_set`,
	PROJECTS_LOAD: `${prefix} projects`,
	PROJECTS_SET: `${prefix} projects_set`,
	PROJECTS_LOADING: `${prefix} projects_loading`,
	FINISHED_PROJECTS_LOAD: `${prefix} finished_projects`,
	FINISHED_PROJECTS_SET: `${prefix} finished_projects_set`,
};

const actions = {
	...TYPES,
	loadProject: makeActionCreator(TYPES.PROJECT_LOAD),
	setProject: makeActionCreator(TYPES.PROJECT_SET),
	loadProjects: makeActionCreator(TYPES.PROJECTS_LOAD),
	setProjects: makeActionCreator(TYPES.PROJECTS_SET),
	loadFinishedProjects: makeActionCreator(TYPES.FINISHED_PROJECTS_LOAD),
	setFinishedProjects: makeActionCreator(TYPES.FINISHED_PROJECTS_SET),
	projectsLoading: makeActionCreator(TYPES.PROJECTS_LOADING),
};

export default actions;
export { actions as projectActions };
