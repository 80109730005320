import { API_ROUTES } from '../constants/routes';
import { BaseService } from './BaseService';

class HomepageService extends BaseService {
  constructor() {
    super(API_ROUTES.root);
  }

  getHomepageBlocks = async () => {
    const { agent } = this;

    try {
      return await agent.get(`/api/0.1${API_ROUTES.homepage}`);
    } catch (err: any) {
      return err.response
    }
  };

  getHomepageSections = async () => {
    const { agent } = this;

    try {
      return await agent.get(`/api/0.1${API_ROUTES.homepageSections}`);
    } catch (err: any) {
      return err.response
    }
  };
};

const Service = new HomepageService();

export default Service;
export { Service as HomepageService };
