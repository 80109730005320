import { Link } from 'react-router-dom';
import day from 'dayjs';
import { Marker, Popup } from 'react-leaflet';

import ProgressBar from 'components/progress-bar';
import Divider from 'components/divider';
import { convertToEuro, dateDiffInDays } from 'utils/helpers';

import { projectDonationsTypes } from 'interfaces';
import { RECEIVED_CASH_STATUSES, PENDING_STATUS } from 'constants/payment-statuses';
import {  projectIcon  } from './icon';

import * as S from './styles';

interface Props {
  alias: string;
  imageBig: string;
  title: string;
  finishedAt: string;
  fundSum: string;
  amount: number;
  isFinished: boolean;
  projectDonations: projectDonationsTypes[];
  latitude: string;
  longitude: string;
};

const ProjectMarker = (props: Props) => {
  const { fundSum, finishedAt, alias, title, isFinished, projectDonations, latitude, longitude } = props;
  const targetSum = parseInt(fundSum, 10)
  const receivedDonations = projectDonations.filter(d => RECEIVED_CASH_STATUSES.includes(d.status))
  const pendingDonations = projectDonations.filter(d => PENDING_STATUS.includes(d.status) && d.isShowSepaPeriod);
  const receivedSum = receivedDonations.reduce((accumulator, current) => accumulator + parseInt(current.donationSum), 0);
  const pendingSum = pendingDonations.reduce((accumulator, current) => accumulator + parseInt(current.donationSum), 0);
  const progressMain = Math.ceil(receivedSum / targetSum * 100) || 0;
  const progressRecent = Math.ceil(pendingSum / targetSum * 100) || 0;
  const leftDays = dateDiffInDays(new Date(), new Date(finishedAt));
  const totallMinutesToEnd = day(finishedAt).diff(day(), 'minute');
  const isCompleted = isFinished || totallMinutesToEnd < 0;

  const renderDaysCount = () => (
    <S.Item>
      <S.ItemDesc>noch</S.ItemDesc>
      <S.ItemValue>{ leftDays } Tage</S.ItemValue>
    </S.Item>
  )

  const renderCompleted = () => (
    <S.Item><S.ItemDesc>Beendet</S.ItemDesc></S.Item>
  )

  return (
    <Marker
      position={[parseFloat(latitude), parseFloat(longitude)]}
      icon={projectIcon(isCompleted)}
    >
      <Popup className='project-popup'>
        <S.Wrapper>
          <S.LinkWrapper><Link to={`/project/${alias}`}><S.Title>{ title }</S.Title></Link></S.LinkWrapper>
          <ProgressBar main={progressMain} recent={progressRecent} source={'card'} />
          <S.Stats>
            <S.Item>
              <S.Row>
                <S.ItemValue>{ convertToEuro(receivedSum) }</S.ItemValue>
                { pendingSum > 0 && <S.PendingSum>(+{convertToEuro(pendingSum)})</S.PendingSum> }
              </S.Row>
              <S.ItemDesc>finanziert</S.ItemDesc>
            </S.Item>
            <Divider vertical={true} />
            <S.Item>
              <S.ItemValue>{ convertToEuro(targetSum) }</S.ItemValue>
              <S.ItemDesc>benötigt</S.ItemDesc>
            </S.Item>
            <Divider vertical={true} />
            { isCompleted ? renderCompleted() : renderDaysCount() }
          </S.Stats>
          <S.Links>
            <span><Link to={`/project/${alias}`}>Details</Link></span>
          </S.Links>
        </S.Wrapper>
      </Popup>
    </Marker>
  );
};

export default ProjectMarker;
