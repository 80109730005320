import { all, takeLatest, put, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { UI_ROUTES } from '../../constants/routes';
import { AuthService } from '../../services/AuthService';
import { StorageUtils } from '../../utils/StorageUtils';
import actions from './actions';

function* login(args) {
  const { payload } = args;

  const response = yield call(AuthService.login, payload);

  yield put(actions.setLoginResponse(response));

  if (!response?.data.access_token) return;

  const { data } = response;

  yield call(StorageUtils.storeToken, data.access_token);

  const user = yield call(AuthService.profile);

  if (user && user.email) {
    yield put(actions.profileRefresh(user));

    const currentPath = window.location.pathname
    const isDonationPage = currentPath.includes('/donation/step')
    const donationUrl = currentPath.replace('/step1', '/step2')
    const nextPath = isDonationPage ? donationUrl : UI_ROUTES.root

    yield put(push(nextPath));

  } else {
    yield call(StorageUtils.clearAll);
    yield put(push(UI_ROUTES.login));
  }
}

function* logout() {
    yield put(actions.profileRefresh(null));
    yield call(StorageUtils.clearAll);
    yield put(push(UI_ROUTES.register));

}

function* register(args) {
  const { payload } = args;
  const response = yield call(AuthService.register, payload);

  yield put(actions.setSignupResponse(response));

  if (response?.status === 201) {
    yield put(push(UI_ROUTES.complete));
  }
}

function* resetpassword(args) {
  const { payload } = args;

  const response = yield call(AuthService.reset, payload);

  yield put(actions.setResetPasswordResponse(response));

  if (response?.status === 200) {
    yield put(push(UI_ROUTES.resetpassword));
  }
}

function* resetpasswordConfirm(args) {
  const { payload } = args;

  const response = yield call(AuthService.resetConfirm, payload);

  yield put(actions.setResetPasswordConfirmResponse(response));

  if (response?.status === 200) {
    yield put(push(UI_ROUTES.register));
  }
}

function* regConfirm(args) {
  const { payload } = args;

  const response = yield call(AuthService.regConfirm, payload);

  yield put(actions.setRegConfirmResponse(response));

  if (response?.data?.detail) return;

  if (!response?.data?.access_token) {
    yield call(StorageUtils.clearAll);
    yield put(push(UI_ROUTES.login));

    return;
  }

  const { data } = response;

  yield call(StorageUtils.storeToken, data.access_token);

  const user = yield call(AuthService.profile);

  if (user && user.email) {
    yield put(actions.profileRefresh(user));
    yield put(push(UI_ROUTES.root));
  } else {
    yield call(StorageUtils.clearAll);
    yield put(push(UI_ROUTES.login));
  }
}

function* updateUser(args) {
  const { payload } = args;
  const response = yield call(AuthService.updateUser, payload);

  yield put(actions.setUpdateUserResponse(response));

  if (response?.status === 200) {
    yield put(actions.profileRefresh(response.data))
    yield put(actions.alertForUpdate({ isVisible: true }));

    window.scrollTo(0, 0);
  }
}

export default function* authSaga() {
  yield all([
    takeLatest(actions.LOGIN, login),
    takeLatest(actions.LOGOUT, logout),
    takeLatest(actions.REGISTER, register),
    takeLatest(actions.RESETPASSWORD, resetpassword),
    takeLatest(actions.RESETPASSWORD_CONFIRM, resetpasswordConfirm),
    takeLatest(actions.REG_CONFIRM, regConfirm),
    takeLatest(actions.UPDATE_USER, updateUser),
  ]);
}
