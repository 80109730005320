import actions from './actions';
import { homepageSectionTypes, homepageBlockTypes } from '../../interfaces'

export interface IHomepage {
	homepageBlocks: Array<homepageBlockTypes>,
	homepageSections: Array<homepageSectionTypes>,
	loading: null|any,
}

const initState: IHomepage = {
	homepageBlocks: [],
	homepageSections: [],
	loading: { homePage: false, sections: false },
};

export default function homepageReducer(state = initState, { type, payload }) {
	switch (type) {
		case actions.HOMEPAGE_BLOCKS_SET: {
			return { ...state, homepageBlocks: payload }
		}
		case actions.HOMEPAGE_BLOCKS_LOADING: {
			return { ...state, loading: { ...state.loading, ...payload } }
		}
		case actions.HOMEPAGE_SECTIONS_SET: {
			return { ...state, homepageSections: payload }
		}
		case actions.HOMEPAGE_SECTIONS_LOADING: {
			return { ...state, loading: { ...state.loading, ...payload } }
		}
		default: {
			return state;
		}
	}
}
