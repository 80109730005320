import { createSelector } from 'reselect';
import { IProfile } from './reducer';

interface IParams {
  Profile: IProfile
}

const profile = ({ Profile }: IParams) => Profile.profile;
const loading = ({ Profile }: IParams) => Profile.loading;

export const selectProfile = createSelector([profile], profile => profile);
export const selectLoading = createSelector([loading], loading => loading);
