import { API_ROUTES } from '../constants/routes';
import { BaseService } from './BaseService';

class SupporterService extends BaseService {
  constructor() {
    super(API_ROUTES.root);
  }

  loadSupporters = async (projectId) => {
    const { agent } = this;

    try {
      return await agent.get(`/api/0.1${API_ROUTES.supporters}`, { params: { project_id: projectId }});
    } catch (err) {
      return err.response;
    }
  };
}

const Service = new SupporterService();

export default SupporterService;
export { Service as SupporterService };
