import { makeActionCreator } from '../../utils/ReduxUtils';

const prefix = '[StaticPages]';

const TYPES = {
	STATIC_PAGE_LOAD: `${prefix} static_page`,
	STATIC_PAGE_SET: `${prefix} static_page_set`,
	STATIC_PAGES_LOAD: `${prefix} static_pages`,
	STATIC_PAGES_SET: `${prefix} static_pages_set`,
	FAQ_LOAD: `${prefix} faq`,
	FAQ_SET: `${prefix} faq_set`,
};

const actions = {
	...TYPES,
	loadStaticPage: makeActionCreator(TYPES.STATIC_PAGE_LOAD),
	setStaticPage: makeActionCreator(TYPES.STATIC_PAGE_SET),
	loadStaticPages: makeActionCreator(TYPES.STATIC_PAGES_LOAD),
	setStaticPages: makeActionCreator(TYPES.STATIC_PAGES_SET),
	loadFaq: makeActionCreator(TYPES.FAQ_LOAD),
	setFaq: makeActionCreator(TYPES.FAQ_SET),
};

export default actions;
export { actions as staticPagesActions };
