import { makeActionCreator } from '../../utils/ReduxUtils';

const prefix = '[Auth]';

const TYPES = {
	LOGIN: `${prefix} login`,
	LOGIN_RESPONSE_SET: `${prefix} login_response_set`,
	LOGOUT: `${prefix} logout`,
	REGISTER: `${prefix} register`,
	SIGNUP_RESPONSE_SET: `${prefix} signup_response_set`,
	UPDATE_USER: `${prefix} update`,
	UPDATE_USER_RESPONSE_SET: `${prefix} update_response_set`,
	RESETPASSWORD: `${prefix} resetpassword`,
	RESETPASSWORD_RESPONSE_SET: `${prefix} resetpassword_response_set`,
	RESETPASSWORD_CONFIRM: `${prefix} resetpassword_confirm`,
	RESETPASSWORD_CONFIRM_RESPONSE_SET: `${prefix} resetpassword_confirm_response_set`,
	REG_CONFIRM: `${prefix} reg_confirm`,
	REG_CONFIRM_RESPONSE_SET: `${prefix} reg_confirm_response_set`,
	ALERT_FOR_UPDATE: `${prefix} alert_for_update`,

	PROFILE_RELOAD: `${prefix} profile-reload`,
	PROFILE_REFRESH: `${prefix} profile-refresh`,
};

const actions = {
	...TYPES,
	login: makeActionCreator(TYPES.LOGIN),
	setLoginResponse: makeActionCreator(TYPES.LOGIN_RESPONSE_SET),
	logout: makeActionCreator(TYPES.LOGOUT),
	register: makeActionCreator(TYPES.REGISTER),
	setSignupResponse: makeActionCreator(TYPES.SIGNUP_RESPONSE_SET),
	updateUser: makeActionCreator(TYPES.UPDATE_USER),
	setUpdateUserResponse: makeActionCreator(TYPES.UPDATE_USER_RESPONSE_SET),
	resetpassword: makeActionCreator(TYPES.RESETPASSWORD),
	setResetPasswordResponse: makeActionCreator(TYPES.RESETPASSWORD_RESPONSE_SET),
	resetpasswordConfirm: makeActionCreator(TYPES.RESETPASSWORD_CONFIRM),
	setResetPasswordConfirmResponse: makeActionCreator(TYPES.RESETPASSWORD_CONFIRM_RESPONSE_SET),
	regConfirm: makeActionCreator(TYPES.REG_CONFIRM),
	setRegConfirmResponse: makeActionCreator(TYPES.REG_CONFIRM_RESPONSE_SET),
	alertForUpdate: makeActionCreator(TYPES.ALERT_FOR_UPDATE),

	profileReload: makeActionCreator(TYPES.PROFILE_RELOAD),
	profileRefresh: makeActionCreator(TYPES.PROFILE_REFRESH),
};

export default actions;
export { actions as authActions };
