import { useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useMount } from 'react-use';
import { Row, Col, Accordion, Card, useAccordionToggle, AccordionContext } from 'react-bootstrap';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';

import { selectFaq } from '../../redux/static-pages/selectors';
import { staticPagesActions } from '../../redux/static-pages/actions';
import { faqCategoryTypes, faqAnswerTypes } from '../../interfaces'

import * as S from './styles';

type Props = {};

const FaqPage = (props: Props) => {
  const dispatch = useDispatch();
  const faq = useSelector(selectFaq);

  useMount(() => {
    dispatch(staticPagesActions.loadFaq());
  });

  const renderCategory = (category: faqCategoryTypes) => (
    <div key={category.category_id} className='mb-5'>
      <Row>
        <Col>
          <h4>{category.category_title}</h4>
        </Col>
      </Row>
      <Row>
        <Col>
          { category.faq_answers.sort((a, b) => a.order_pos - b.order_pos).map(renderQuestion) }
        </Col>
      </Row>
    </div>
  );

  const renderQuestion = (blockData: faqAnswerTypes) => {
    const Toggle = ({ children, eventKey, callback }: any) => {
      const currentEventKey = useContext(AccordionContext);

      const decoratedOnClick = useAccordionToggle(
        eventKey,
        () => callback && callback(eventKey),
      );

      const isCurrentEventKey = currentEventKey === eventKey;

      return (
        <span
          onClick={decoratedOnClick}
          className='d-flex flex-row justify-content-between align-items-center'
        >
          {children} { isCurrentEventKey ? <BsChevronUp /> : <BsChevronDown /> }
        </span>
      );
    }

    return (
      <Row key={blockData.id}>
        <S.StyledTextBlockCol>
          <S.StyledAccordion>
            <Card>
              <Card.Header>
                <Toggle eventKey='0'> { blockData.question }</Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey='0'>
                <Card.Body dangerouslySetInnerHTML={{ __html: blockData.answer }} />
              </Accordion.Collapse>
            </Card>
          </S.StyledAccordion>
        </S.StyledTextBlockCol>
      </Row>
    )
  };

  return (
    <>
      <S.HeaderContainer fluid='lg'>
        <Row><Col><h1>Häufig gestellte Fragen</h1></Col></Row>
      </S.HeaderContainer>
      <S.Divider />
      <S.ContentContainerWithBg fluid>
        <S.ContentContainer fluid='lg'>
          { faq.length ? faq.sort((a, b) => a.order_pos - b.order_pos).map(renderCategory) : 'Keine Treffer' }
        </S.ContentContainer>
      </S.ContentContainerWithBg>
    </>
  )
}

export default FaqPage;
