export const API_ROUTES = {
  root: '/',

  // Auth
  login: '/user/login',
  register: '/user',
  reset: '/forgot-password/request',
  homepage: '/home-page-blocks',
  homepageSections: '/home-page-sections',
  regConfirm: '/registration/confirm',
  resetConfirm: '/forgot-password/confirm',
  currentUser: '/user/info',
  payment: '/payment',

  supporters: '/supporters',
  projects: '/projects',
  profiles: '/user/uid',
  projectComments: '/project-comments',
  staticPages: '/default-static-pages',
  faq: '/faq'
};

export const UI_ROUTES = {
  root: '/',

  // Auth
  login: '/',
  register: '/auth',
  complete: '/registrierung-vervollstaendigen',
  resetpassword: '/new-password',
  resetConfirm: '/forgot-password/confirm',

  // Static Pages
  ibsh: '/seite/ibsh',
  datenschutz: '/seite/datenschutz',
  impressum: '/seite/impressum',
  barrierefreiheit: '/seite/barrierefreiheit',
  nutzungsbedingungen: '/seite/nutzungsbedingungen',
  projektbedingungen: '/seite/nutzungsbedingungen-Projektstarter',
  presse: '/seite/presse',
  soGehts: '/seite/so-gehts',
  darumGehts: '/seite/darum-gehts',
  partner: '/seite/paten-und-partner'
};
