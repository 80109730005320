import styled from 'styled-components';

import { Container } from 'react-bootstrap';

export const ImageContainer = styled(Container)`
  padding: 0;

  & img {
    width: 100%;
    margin-bottom: 100px;

    @media screen and (max-width: 720px) {
      margin-bottom: 55px;
    }
  }

  & div {
    padding: 0;
    margin: 0;
  }
`;

export const DescriptionContainer = styled(Container)`
  margin-bottom: 100px;

  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.444444px;
  color: #515151;
`;
