import Forest from './forest';
import Projects from './projects';
import Info from './info';
import Quotes from './quotes';
import Map from './map';

type Props = {};

const Homepage = (props: Props) => {
  return (
    <div className='d-flex flex-column'>
      <Forest />
      <Projects />
      <Info />
      <Projects finished={true}/>
      <Quotes />
      <Map />
    </div>
  );
};

export default Homepage;
