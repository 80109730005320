import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { authActions } from '../../redux/auth/actions';
import { selectRegConfirmResponse } from '../../redux/auth/selectors';
import * as S from './styles';

interface ParamTypes {
  key: string;
}

const RegComplete = () => {
  const { key } = useParams<ParamTypes>();

  const regConfirmResponse = useSelector(selectRegConfirmResponse);

  const dispatch = useDispatch();

  const error = regConfirmResponse?.data?.detail;

  const onClickStarten = (event) => {
    event.preventDefault();
    event.stopPropagation();

    dispatch(authActions.regConfirm(key));
  };

  const renderConfirm = () => (
    <section>
      <Row>
        <Col>
          <h4 className='mb-3'>Ihr Nutzerkonto wurde erfolgreich aktiviert.</h4>
          <Button variant='danger' onClick={onClickStarten}>
            Jetzt starten
          </Button>
          <p className='text-danger'>{error}</p>
        </Col>
      </Row>
    </section>
  )

  const renderCheckEmail = () => (
    <span>Bitte prüfen Sie Ihre E-Mails und aktivieren Sie Ihr Konto durch Klick auf den Bestätigungslink.</span>
  )

  return (
    <S.Wrapper>
      <S.StyledContainer fluid='lg'>
        <S.Content>
          <h2>Vielen Dank für ihre Registrierung</h2>
          { key ? renderConfirm() : renderCheckEmail() }
        </S.Content>
      </S.StyledContainer>
    </S.Wrapper>
  );
};

export default RegComplete;
