import {Button, Collapse} from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';

interface DonationParams {
  alias: string;
}

type Props = {
  open: boolean
};

const CollapseSubmit = ({ open }: Props) => {
  const { alias } = useParams<DonationParams>();

  return (
    <Collapse in={open}>
      <div id='example-collapse-text3'>
        <Link to={`/project/${alias}/donation/step2`}><Button variant='primary'>Weiter als Gast</Button></Link>
      </div>
    </Collapse>
  )
}

export default CollapseSubmit;
