import { all, takeLatest, put, call } from 'redux-saga/effects';

import { ProjectService } from '../../services/ProjectService';
import actions from './actions';

function* loadProject(data) {
  const { payload } = data;

  yield put(actions.projectsLoading({ project: true }));

  const response = yield call(ProjectService.loadProject, payload);

  if (response?.status === 200) {
    yield put(actions.setProject(response.data));
  }

  yield put(actions.projectsLoading({ project: false }));
}

function* loadProjects(data) {
  const { payload } = data;

  yield put(actions.projectsLoading({ projects: true }));

  const response = yield call(ProjectService.loadProjects, payload);

  if (response?.status === 200) {
    yield put(actions.setProjects(response.data));
  }

  yield put(actions.projectsLoading({ projects: false }));
}

function* loadFinishedProjects(data) {
  const { payload } = data;

  yield put(actions.projectsLoading({ projects: true }));

  const response = yield call(ProjectService.loadProjects, { ...payload, status: 'ended' });

  if (response?.status === 200) {
    yield put(actions.setFinishedProjects(response.data));
  }

  yield put(actions.projectsLoading({ projects: false }));
}

export default function* projectSaga() {
  yield all([
    takeLatest(actions.PROJECT_LOAD, loadProject),
    takeLatest(actions.PROJECTS_LOAD, loadProjects),
    takeLatest(actions.FINISHED_PROJECTS_LOAD, loadFinishedProjects),
  ]);
}
