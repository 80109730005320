import {useState} from 'react';
import {Container, Form, FormLabel} from 'react-bootstrap';
import {Donation} from '../../stats/donation';

import Login from '../collapse/login';
import Registration from '../collapse/registration';
import CollapseSubmit from '../collapse/submit';

const RenderRadios = () => {
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);

  return (
    <Container fluid>
      <Form>
        <Form.Group controlId='exampleForm'>
          <FormLabel>{Donation.form_label}</FormLabel>
          <div key='login' className='mb-3'>
            <Form.Check
              type={'radio'}
              id={`login-radio`}
              name='group1'
              label={Donation.radioButtons[0].label}
              onChange={() => {
                setOpen1(true);
                setOpen2(false);
                setOpen3(false);
              }}
              aria-controls='example-collapse-text1'
              aria-expanded={open1}
            />
          </div>
          <div key='reg' className='mb-3'>
            <Form.Check
              type={'radio'}
              id={`reg-radio`}
              name='group1'
              label={Donation.radioButtons[1].label}
              onChange={() => {
                setOpen1(false);
                setOpen2(true);
                setOpen3(false);
              }}
              aria-controls='example-collapse-text2'
              aria-expanded={open2}
            />
          </div>
          <div key='anon' className='mb-3'>
            <Form.Check
              type={'radio'}
              id={`anon-radio`}
              name='group1'
              label={Donation.radioButtons[2].label}
              onChange={() => {
                setOpen1(false);
                setOpen2(false);
                setOpen3(true);
              }}
              aria-controls='example-collapse-text3'
              aria-expanded={open3}
            />
          </div>
        </Form.Group>
      </Form>

      <Login open={open1} />
      <Registration open={open2} />
      <CollapseSubmit open={open3} />
    </Container>
  )
}

export default RenderRadios;
