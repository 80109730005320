import { useSelector } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';

import styled from 'styled-components';
import { BsGeoAlt } from 'react-icons/bs';

import { selectProject } from '../../../redux/project/selectors';

type Props = {};

const Link = styled.a`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.3em;
  color: #064a78;
  text-decoration-line: none;

  @media screen and (max-width: 575px) {
    font-size: 16px;
  }
`;

const LinkWrapper = styled(Row)`
  margin-top: 20px;
`;

const ProjectName = styled.h2`
  margin-top: 25px;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 1.1;
  margin-bottom: 20px;
  color: #003064;

  @media screen and (max-width: 575px) {
    text-transform: none;
    font-size: 26px;
  }
`;

const ProjectLocation = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 34px;
  color: #003064;
  margin-bottom: 20px;
`;

const ProjectHeader = (props: Props) => {
  const project = useSelector(selectProject);

  const renderLocation = () => (
    <Row>
      <Col>
        <ProjectLocation>
          <BsGeoAlt color='#D4004B' size='35' /> {project?.projectCity}
        </ProjectLocation>
      </Col>
    </Row>
  );

  return (
    <Container fluid>
      <Container fluid='lg'>
        <LinkWrapper>
          <Col>
            <Link href='/#projects'>{'< zurück zur Startseite'}</Link>
          </Col>
        </LinkWrapper>
        <Row>
          <Col>
            <ProjectName>{project?.title}</ProjectName>
          </Col>
        </Row>
        { project?.projectCity && renderLocation() }
      </Container>
    </Container>
  );
};

export default ProjectHeader;
