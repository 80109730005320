import { useState } from 'react';
import { Link } from 'react-router-dom';
import day from 'dayjs';

import * as S from './styles';
import { projectCommentTypes } from 'interfaces';

const Comment = (props: projectCommentTypes) => {
  const { createdAt, text, member, isInitiator } = props;
  const limitExceeded = text.length > 210;

  const [isSevered, setIsSevered] = useState(limitExceeded ? true : false);

  const profileUrl = `/profile/${member?.uid}`;
  const buttonText = isSevered ? ' mehr' : ' weniger';

  const handleChangeState = () => setIsSevered(!isSevered);

  return (
    <div className='d-flex flex-column'>
      <div className='d-flex flex-row'>
        <S.Avatar src={ member.avatar || '/person.svg' } roundedCircle className='mr-3' />
        <S.UserDataWrapper>
          <S.NameWrapper className='d-flex flex-column mr-3 mr-auto'>
            <div className='font-weight-bold'><Link to={profileUrl}>{member.firstName} {member.lastName}</Link></div>
            { isInitiator && <S.About><Link to={profileUrl}>Projektstarter</Link></S.About> }
          </S.NameWrapper>
          <S.Date>am {day(createdAt).format('DD.MM.YYYY')}</S.Date>
        </S.UserDataWrapper>
      </div>
      <S.CommentWrapper>
        { isSevered ? text.slice(0, 200) : text }
        { limitExceeded && <S.HideButton onClick={handleChangeState}>{buttonText}</S.HideButton> }
      </S.CommentWrapper>
    </div>
  )
};

export default Comment;
