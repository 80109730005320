import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;
`;

export const FormHeader = styled.div`
  color: #003064;
  font-size: 26px;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 20px;
`;

export const PasswordForgotten = styled.div`
  margin: 20px 0;
`;

export const RequiredFieldDescription = styled.div`
  margin-top: auto;
`;
