import { agent, userAgent } from './agent';
import { AxiosInstance } from 'axios';

class BaseService {
	public agent: AxiosInstance;
	public url: string;
	public userAgent: AxiosInstance;

	constructor(url) {
		this.url = url;
		this.agent = agent;
		this.userAgent = userAgent;
	}

	find = async (params = {}) => {
		try {
			const result = await agent.get(this.url, { params });
			return result.data;
		} catch (err) {
			return null;
		}
	};

	findByID = async (id, params = {}) => {
		try {
			const result = await agent.get(`${this.url}/${id}`, { params });
			return result.data;
		} catch (err) {
			return null;
		}
	};

	create = async (data, params = {}) => {
		try {
			const result = await agent.post(this.url, data, { params });
			return result.data;
		} catch (err) {
			return null;
		}
	};

	update = async (id, data, params = {}) => {
		try {
			const result = await agent.put(`${this.url}/${id}`, data, { params });
			return result.data;
		} catch (err) {
			return null;
		}
	};

	remove = async (id: number|string) => {
		try {
			const result = await agent.delete(`${this.url}/${id}`);
			return result.data;
		} catch (err) {
			return null;
		}
	};
}

export default BaseService;
export { BaseService };
