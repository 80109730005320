import Auth from './auth/reducer';
import App from './app/reducer';
import Donation from './donation/reducer';
import Homepage from './home-page/reducer';
import Supporters from './supporters/reducer';
import Project from './project/reducer';
import StaticPages from './static-pages/reducer';
import Comment from './project-comments/reducer';
import Profile from './profile/reducer';

const reducers = {
  App,
  Auth,
  Donation,
  Homepage,
  Supporters,
  Project,
  StaticPages,
  Comment,
  Profile,
};

export default reducers;
