import { makeActionCreator } from '../../utils/ReduxUtils';

const prefix = '[App]';

const TYPES = {
	APP_START: `${prefix} start`,
	UI_LOADING: `${prefix} ui-loading`,
};

const actions = {
	...TYPES,
	appStart: makeActionCreator(TYPES.APP_START),
	uiLoading: makeActionCreator(TYPES.UI_LOADING),
};

export default actions;
export { actions as appActions };
