import styled from 'styled-components';

import { Container, Row } from 'react-bootstrap';

export const StyledContainer = styled(Container)`
  padding: 30px 0;
  background-color: #F2F2F2;

  font-size: 14px;

  & h4 {
    margin-bottom: 20px;
  }

  & a:not(:hover) {
    text-decoration-line: none;
  }

  & button {
    margin-top: 20px;
    width: 220px;
  }
`;

export const StyledRow = styled(Row)`
  background-color: #ffffff;
  padding: 30px 30px;
  margin: 0;
`;
