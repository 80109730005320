export const Donation = {
  name: 'Name of the project',
  info_text: 'Anmeldung',
  ssl_label: 'Sicher spenden:',
  ssl_text: 'Ihre Daten werden über eine verschlüsselte Verbindung (SSL) übertragen.',
  form_label: 'Bitte wählen Sie aus, ob Sie sich einloggen wollen.',
  euro_label: 'Ihre Spendenzusage: * ',
  tables_title: 'Wählen Sie Ihre Zahlungsmethode:',
  radioButtons: [
    {
      label: 'Ich bin bereits registriert und möchte mich einloggen.'
    },
    {
      label: 'Ich möchte mich registrieren.'
    },
    {
      label: 'Ich möchte als Gast spenden und mich nicht registrieren.'
    }
  ],
  table_radios: [
    {
      label: 'Banküberweisung ',
      paragraphs: [
        {
          text: 'Sie erhalten umgehend alle Daten für Ihre Überweisung.\n' +
            'Führen Sie die Zahlung aus, wie Sie möchten - über Ihr Online-Banking, per Banking-App oder\n' +
            'mit einem klassischen Überweisungsträger. Der Zahlungseingang dauert wenige Tage.'
        }
      ]
    },
    {
      label: 'finapi (Online-Überweisung) ',
      paragraphs: [
        {
          text: 'finAPI ist ein Bezahlverfahren für deutsche Banken und Sparkassen, ' +
            'das bei der BaFin (Bundesanstalt für Finanzdienstleistungsaufsicht) für Kontoinformationsdienste ' +
            'und Zahlungsauslösedienste lizensiert und registriert ist. ' +
            'Sie zahlen bequem per Online-Überweisung und dem TAN-Verfahren Ihrer Bank oder Sparkasse.'
        },
        {
          text: 'Ihre Spende wird bis zum Ende der Projektlaufzeit auf einem Treuhandkonto der ' +
            'Investitionsbank Schleswig-Holstein verwaltet. Nach Beendigung der Spendenphase werden eingegangene ' +
            'Spendengelder an die Projektstarterin bzw. den Projektstarter überwiesen. ' +
            'Diese Auszahlung erfolgt unabhängig davon, ob das Spendenziel erreicht wurde oder nicht.'
        }
      ]
    }
  ]
};
