import { createSelector } from 'reselect';
import { IProject } from './reducer';

interface IParams {
  Project: IProject
}

const project = ({ Project }: IParams) => Project.project;
const projects = ({ Project }: IParams) => Project.projects;
const finishedProjects = ({ Project }: IParams) => Project.finishedProjects;
const loading = ({ Project }: IParams) => Project.loading;

export const selectProject = createSelector([project], project => project);
export const selectProjects = createSelector([projects], projects => projects);
export const selectFinishedProjects = createSelector([finishedProjects], finishedProjects => finishedProjects);
export const selectLoading = createSelector([loading], loading => loading);
