import styled from 'styled-components';
import { Image } from 'react-bootstrap';

export const Wrapper = styled.div`
  margin: 20px 0;
  padding: 20px;
  background-color: #cddbe4;
  border-radius: 4px;
`;

export const Avatar = styled(Image)`
  width: 80px;
  height: 80px;
`;

export const InfoBlock = styled.div`
  padding: 15px;
  background: #FFFFFF;
  margin: 0 -15px 20px 5px;

  @media screen and (max-width: 575px) {
    margin: 20px -15px;
  }
`;

export const InfoBlockHeader = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 1em;
  margin: 12px 0 24px;
  color: #003064;
`;

export const Name = styled.p`
  margin: 0 0 10px;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.3em;

  > a {
    text-decoration: none;
  }
`;

export const Link = styled.p`
  margin: 0 0 10px;
  font-size: 14px;
  line-height: 1.3em;

  > a {
    text-decoration: none;
    font-weight: normal;
  }
`;
