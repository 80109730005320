import { createSelector } from 'reselect';
import { IApp } from './reducer';

interface IParams {
  App: IApp
}

const uiLoading = ({ App }: IParams) => App.uiLoading;

export const selectUiLoading = createSelector([uiLoading], uiLoading => uiLoading);
