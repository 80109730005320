import styled from 'styled-components';
import { Container, Col, Row } from 'react-bootstrap';

export const TopCol = styled(Col)`
  background: linear-gradient(170deg, #fff 40%, #003064 40.2%);

  @media screen and (max-width: 720px) {
    background: linear-gradient(170deg, #fff 70%, #003064 70.2%);
  }
`;

export const CustomRow = styled(Row)`
  margin: 0;

  @media screen and (max-width: 720px) {
    margin: 0 -15px;
  }
`;

export const ContentContainerWithBg = styled(Container)`
  padding-top: 35px;
  padding-bottom: 160px;

  background-color: #2E4A7D;

  background-image: url('/project_bg.svg');
  background-position: right bottom;
  background-repeat: no-repeat;

  @media screen and (max-width: 720px) {
    background-image: none;
    padding: 35px 0;
  }
`;

export const ContentContainer = styled(Container)`
  background-color: #ffffff;
  padding: 30px 0;

  & button {
    width: 100%;
    border: 1px solid #455B8C !important;
    background: #003064 !important;
    color: #ffffff;
    margin-bottom: 20px;
  }

  @media screen and (max-width: 720px) {
    padding: 30px 10px;
  }
`;

export const ErrorWrapper = styled.div`
  width: 280px;

  & h1 {
    font-size: 60px;
    font-weight: 700;
    line-height: 0.6em;
    margin: 20px 0;
    text-shadow: 0 0 5px #fff;
    text-transform: none;
  }

  & small {
    font-size: 26px;
    font-weight: 700;
    line-height: 1em;
  }

  & p {
    font-size: 18px;
    line-height: 1.2em;
    margin-top: 30px;
    text-shadow: 0 0 5px #fff, 0 0 5px #fff;
    color: #000;
  }
`;

export const ButtonsTitle = styled.p`
  color: #003064;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 40px;
  margin-top: 45px;
`;

export const Img = styled.img`
  max-width: 100%;
  height: auto;
  float: right;
`;
