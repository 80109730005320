import styled from 'styled-components';

import { Card, Container } from 'react-bootstrap';

export const Wrapper = styled.div`
  background-image: url('/quotes_bg.png');
  background-position: center bottom;
  background-repeat: no-repeat;
  background-color: #EDEDED;
  background-size: cover;
`;

export const StyledContainer = styled(Container)`
  padding: 115px 0;

  @media (max-width: 992px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

export const StyledCard = styled(Card)`
  min-height: 600px;
  border-radius: 0;

  .card-img-top {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
`;

export const Description = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #515151;
`;
