import styled from 'styled-components';

export const FooterWrapper = styled.footer`
  background: #003064;
  color: #fff;
  padding-top: 15px;
  padding-bottom: 15px;

  & a {
    color: #fff !important;
  }
`;

export const FooterLogo = styled.img`
  margin-bottom: 15px;
`;

export const FooterHeader = styled.div`
  border-bottom: 1px solid #C3C8DC;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
  margin-bottom: 5px;
  margin-top: 10px;
  padding-bottom: 9px;
`;

export const FooterText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.3em;

  margin-bottom: 20px;
  margin-top: 5px;
`;

export const FooterNav = styled.ul`
  list-style-type: none;
  padding-left: 0;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.3em;

  & li {
    margin-bottom: 10px;
  }

  & a {
    font-weight: normal;
    text-decoration: none !important;
  }

  & a:hover {
    text-decoration: underline !important;
  }
`;

export const IconMegaphone = styled.span`
  display: inline-block;
  font-size: 27px;
  margin-right: 10px;
  margin-top: -10px;
  position: relative;
  top: 3px;
`;

export const FacebookLogo = styled.img`
  margin-top: 10px;
  width: 30px;

  @media screen and (max-width: 720px) {
    width: 50px;
  }
`;
