import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Nav } from 'react-bootstrap';

import { authActions } from '../../redux/auth/actions';
import { selectUser } from '../../redux/auth/selectors';

import ProfileCard from './profile-card';
import ProfileForm from './profile-form';

import * as S from './styles';

type Props = {};

const Profile = (props: Props) => {
  const user = useSelector(selectUser);
  const [ currentTab, setCurrentTab ] = useState('profile-card');
  const dispatch = useDispatch();

  const handleProfileCardClick = () => {
    setCurrentTab('profile-card')
  }

  const handleProfileFormClick = () => {
    setCurrentTab('profile-form')
  }

  const handleSignOut = () => {
    dispatch(authActions.logout());
  }

  const renderHeader = () => {
    switch (currentTab) {
      case 'profile-card': return (
        <Row className='mb-5'>
          <Col>
            <h2>{user.username}</h2>
            <div>{user.city}</div>
          </Col>
        </Row>
      )
      case 'profile-form': return (
        <Row className='mb-5'>
          <Col>
            <h2>Herzlich willkommen {user.username}</h2>
          </Col>
        </Row>
      )
      default: return null;
    }
  }

  const renderTab = () => {
    switch (currentTab) {
      case 'profile-card': return <ProfileCard />
      case 'profile-form': return <ProfileForm />
      default: return null;
    }
  }

  return (
    <S.StyledContainer fluid>
      <Container fluid='lg'>
        { renderHeader() }
        <Row>
          <Col sm={8}>
            { renderTab() }
          </Col>
          <S.NavCol sm={4}>
            <Nav className='flex-column' as='ul' activeKey={currentTab}>
              <Nav.Item as='li'>
                <Nav.Link as='span' eventKey='profile-card' onClick={handleProfileCardClick}>Profil</Nav.Link>
              </Nav.Item>
              <Nav.Item as='li'>
                <Nav.Link as='span' eventKey='profile-form' onClick={handleProfileFormClick}>Profil bearbeiten</Nav.Link>
              </Nav.Item>
              <Nav.Item as='li'>
                <Nav.Link as='span' onClick={handleSignOut}>Ausloggen</Nav.Link>
              </Nav.Item>
            </Nav>
          </S.NavCol>
        </Row>
      </Container>
    </S.StyledContainer>
  )
}

export default Profile;
