import styled from 'styled-components';

import { Container, Image, Col } from 'react-bootstrap';

export const StyledContainer = styled(Container)`
  padding: 60px 0;
  background-color: #F2F2F2;
`;

export const StyledImage = styled(Image)`
  width: 212.5px;
  height: 212.5px;

  @media (max-width: 768px) {
    display: block;
    margin: auto;
  }
`;

export const ProfileContainer = styled(Container)`
  background-color: #ffffff;
  padding: 30px;

  & h2 {
    font-size: 26px;
    font-weight: 700;
    text-transform: none;
    line-height: 1.1;
  }

  & strong {
    font-size: 18px;
    line-height: 1;
  }
`;

export const ColAbout = styled(Col)`
  font-size: 14px;
  line-height: 1.3;
`;

export const StatisticCol = styled(Col)`
  border-left: 1px solid gray;
  font-size: 14px;
  line-height: 1.3;

  & h6, strong {
    font-size: 14px;
    line-height: 1.3;
  }

  & h6 {
    margin-bottom: 15px;
  }

  @media (max-width: 768px) {
    border: none;
  }
`;

export const LinksList = styled.ul`
  list-style: none;
  padding: 0;

  & li {
    margin-bottom: 5px;
  }

  & a {
    margin-left: 5px;
    text-decoration-line: none;
    font-weight: normal;

    &:hover {
      text-decoration-line: none;
    }
  }
`;
