import * as S from './styles';
import { convertToEuro } from '../../../../utils/helpers';

type Props = {
  avatar?: null|string,
  supporterName: string,
  text?: string,
  donationSum: string,
  projectDonationId: number
};

const Supporter = (props: Props) => {
  const { avatar, supporterName, text, donationSum } = props;

  return (
    <S.Wrapper className='d-flex flex-row align-items-center'>
      <S.Avatar src={ avatar || '/person.svg' } roundedCircle className='mr-3' />
      <div className='d-flex flex-column mr-3 mr-auto'>
        <div className='font-weight-bold'>{ supporterName }</div>
        <div dangerouslySetInnerHTML={{ __html: (text || '').replaceAll('\r\n', '<br/>') }} />
      </div>
      <S.Amount>{ convertToEuro(donationSum) }</S.Amount>
    </S.Wrapper>
  )
}

export default Supporter
