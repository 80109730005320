import { API_ROUTES } from 'constants/routes';
import { BaseService } from './BaseService';

class ProfileService extends BaseService {
  constructor() {
    super(API_ROUTES.root);
  }

  loadProfile = async (userId: number) => {
    const { agent } = this;

    try {
      return await agent.get(`/api/0.1${API_ROUTES.profiles}/${userId}`);
    } catch (err) {
      return err.response;
    }
  };
}

const Service = new ProfileService();

export default ProfileService;
export { Service as ProfileService };
