import styled, { css } from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';

type ProgressType = {
  progress: string | number
};

export const FlexOnDesktop = css`
  @media screen and (min-width: 720px) {
    display: flex;
  }
`;


export const StatsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 20px;
`;

export const StatsLeftDesktop = styled.div`
  flex: 1 1 auto;

  @media screen and (min-width: 720px) {
    margin-right: 25px
  }
`;

export const StatsRightDesktop = styled.div`
  ${FlexOnDesktop}
  flex: 1 1 auto;
`;

export const ProgressContainer = styled.div`
  border-radius: 30px;
  height: 30px;
  overflow: hidden;
  width: 100%;
  background-color: #ccdbe4;
  display: flex;

  margin-bottom: 12px;

  @media screen and (min-width: 720px) {
    height: 50px;
  }
`;

export const ProgressBarCol = styled(Col)`
  @media screen and (max-width: 575px) {
    #progressBar {
      border-radius: 15px;
      height: 30px;
    }
  }
`;

export const ProgressMain = styled.div<ProgressType>`
  background-color: #48a0ae;
  height: 100%;
  width: ${props => props.progress}%;
`;

export const ProgressExtra = styled.div<ProgressType>`
  background-color: #66b7b8;
  height: 100%;
  width: ${props => props.progress}%;
`;

export const ProgressDigitsHeader = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 48px;

  color: #1A1A18;

  @media screen and (min-width: 720px) {
    margin: 0;
  }

  @media screen and (max-width: 575px) {
    font-size: 30px;
    line-height: 30px;
  }
`;

export const ProgressExtraText = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.5px;
  color: #1A1A18;
`;

export const ProgressExtraTextHeader = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.5px;
  color: #1A1A18;

  & svg {
    cursor: help;
  }
`;

export const ProgressTextExtraDesktopRight = styled.div`
  ${FlexOnDesktop};
  justify-content: space-between;
`;

export const ProgressBoldText = styled.div`
  font-weight: 700;
`;

export const IconItem = styled.div`
  display: flex;
  align-items: center;

  & > img {
    width: 30px;
    height: 30px;
    margin-right: 15px;
  }

  & + & {
    margin-top: 10px;
  }

  @media screen and (min-width: 720px) {
    flex: 0 0 30%;
    flex-direction: column;
    justify-content: center;
  }
`;

export const StyledContainer = styled(Container)`
  background-color: #E0E2EE;
  padding: 40px 0;
`;

export const StyledRow = styled(Row)`
  background-color: #ffffff;

  padding: 20px 0 10px 0;
  margin-left: 0;
  margin-right: 0;
`;

export const ItemColStyled = styled(Col)`
  border-left: 1px solid #D7E1E9;

  > img {
    margin-bottom: 12px;
    height: 42px;
  }

  @media screen and (max-width: 575px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-left: none;
    border-bottom: 1px solid #D7E1E9;
    padding-top: 10px;
    padding-bottom: 10px;

    * {
      margin-right: 5px;
    }

    > img {
      margin-bottom: 0;
      height: 30px;
    }
  }
`;

export const StatCol = styled(Col)`
  text-align: right;

  @media screen and (max-width: 575px) {
    text-align: left;
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;

export const StatRow = styled(Row)`
  @media screen and (max-width: 575px) {
    div:last-child {
      border: none;
    }
  }
`;
