import { Row, Col, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link, Redirect, useParams } from 'react-router-dom';

import { selectAmount, selectPaymentResponse } from 'redux/donation/selectors';

import ProjectHeader from '../../project-details/project-header';
import Stats from '../../project-details/stats';

import * as S from './styles';

interface DonationParams {
  alias: string;
}

type Props = {};

const PageDonation = (props: Props) => {
  const { alias } = useParams<DonationParams>();
  const amount = useSelector(selectAmount);
  const { data } = useSelector(selectPaymentResponse);

  if (!data) {
    return <Redirect to={`/project/${alias}/donation/step2`} />;
  }

  return (
    <>
      <ProjectHeader />
      <Stats />
      <S.StyledContainer fluid>
        <S.DefaultContainer fluid='lg'>
          <Row>
            <Col sm={6}>
              <S.InfoBlock>Die Daten für Ihre {amount}-Euro-Überweisung:</S.InfoBlock>
              <S.Text>
                Bitte verwenden Sie bei Ihrer Überweisung die nachfolgenden Daten.
                Achten Sie dabei besonders auf die Richtigkeit des Spendencodes im Verwendungszweck.
                Nur bei einer korrekten Eingabe können wir Ihre Spendenzusage dem Projekt zuordnen.
              </S.Text>
              <S.Text>
                Der Spendencode ist nur einmal gültig und kann daher nicht weitergegeben werden.
              </S.Text>
              <S.Text>
                Bitte führen Sie Ihre Überweisung sobald wie möglich aus.
                Ihre Spendenzusage wird auf WIR BEWALDEN.SH für die nächsten sieben Tage aufrechterhalten.
                Trifft Ihre Spende erst danach bei uns ein,
                kann sie leider nicht mehr zugeordnet werden und wird auf Ihr Konto zurückgebucht.
              </S.Text>
              <Button variant='default' onClick={() => window.print()}>Überweisungsdaten drucken</Button>
            </Col>
            <Col sm={6}>
              <S.InfoBlock>Information zu Ihrer Spende:</S.InfoBlock>
              <S.Text>
                <label>Begünstigter:</label>
                <br/>
                <span>Investitionsbank Schleswig-Holstein</span>
              </S.Text>
              <S.Text>
                <label>IBAN des Begünstigten:</label>
                <br/>
                <span>{data?.escrowConfig?.iban}</span>
              </S.Text>
              <S.Text>
                <label>BIC des Kreditinstituts:</label>
                <br/>
                <span>{data?.escrowConfig?.bic}</span>
              </S.Text>
              <S.Text>
                <label>Spendenbetrag:</label>
                <br/>
                <span>{amount} €</span>
              </S.Text>
              <S.Text>
                <label>Verwendungszweck (Spendencode):</label>
                <br/>
                <span>{data?.donation?.transactionCode}</span>
              </S.Text>
              <Link to={`/project/${alias}/payment-feedback/${data?.donation?.projectDonationId}`}>
                <Button variant='primary'>Spendenprozess abschließen</Button>
              </Link>
            </Col>
          </Row>
        </S.DefaultContainer>
      </S.StyledContainer>
    </>
  );
};

export default PageDonation;
