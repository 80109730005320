import { UI_ROUTES } from '../../constants/routes';

const nutzungsbedingungen = <a href={UI_ROUTES.nutzungsbedingungen} target='_blank' rel='noreferrer'>Nutzungsbedingungen</a>
const datenschutz = <a href={UI_ROUTES.datenschutz} target='_blank' rel='noreferrer'>Datenschutzbestimmungen</a>

const TermsLabel = () => (
  <>
    Ich habe die {nutzungsbedingungen} und {datenschutz} von WIR BEWALDEN.SH gelesen und akzeptiere sie hiermit. *
  </>
)

export default TermsLabel;
