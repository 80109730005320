import actions from './actions';

export interface IProfile {
	profile: null|any,
	loading: null|any,
}

const initState: IProfile = {
	profile: {},
	loading: { profile: false },
};

export default function profileReducer(state = initState, { type, payload }) {
	switch (type) {
		case actions.PROFILE_SET: {
			return { ...state, profile: payload }
		}
		case actions.PROFILE_LOADING: {
			return { ...state, loading: { ...state.loading, ...payload } }
		}
		default: {
			return state;
		}
	}
}
