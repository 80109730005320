import { makeActionCreator } from '../../utils/ReduxUtils';

const prefix = '[Homepage]';

const TYPES = {
  HOMEPAGE_BLOCKS_LOAD: `${prefix} homepage_blocks_load`,
  HOMEPAGE_BLOCKS_SET: `${prefix} homepage_blocks_set`,
  HOMEPAGE_BLOCKS_LOADING: `${prefix} homepage_blocks_loading`,
  HOMEPAGE_SECTIONS_LOAD: `${prefix} homepage_sections_load`,
  HOMEPAGE_SECTIONS_SET: `${prefix} homepage_sections_set`,
  HOMEPAGE_SECTIONS_LOADING: `${prefix} homepage_sections_loading`
};

const actions = {
  ...TYPES,
  loadHomepageBlocks: makeActionCreator(TYPES.HOMEPAGE_BLOCKS_LOAD),
  setHomepageBlocks: makeActionCreator(TYPES.HOMEPAGE_BLOCKS_SET),
  homepageBlocksLoading: makeActionCreator(TYPES.HOMEPAGE_BLOCKS_LOADING),
  loadHomepageSections: makeActionCreator(TYPES.HOMEPAGE_SECTIONS_LOAD),
  setHomepageSections: makeActionCreator(TYPES.HOMEPAGE_SECTIONS_SET),
  homepageSectionsLoading: makeActionCreator(TYPES.HOMEPAGE_SECTIONS_LOADING)
};

export default actions;
export { actions as homepageActions };
