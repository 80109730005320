import styled from 'styled-components';

const Header = styled.div`
	font-size: 24px;
	margin-bottom: 10px;
	font-weight: 500;
	line-height: 1.1;
`;

const Content = styled.div`
	a {
		font-weight: normal;
	}
`;

const CookieBanner = () => (
	<Content>
		<Header>Cookie-Einstellungen</Header>
		<p>
			Wir verwenden Cookies, um Ihnen ein optimales Webseiten-Erlebnis zu bieten. Bei Cookies handelt es sich um
			kleine Textdateien, die eine Website wie die der Spendenplattform auf dem Computer oder dem Smartphone
			eines Website-Besuchers ablegt, also lokal speichert. Für den Betrieb unserer Website und deren Funktion sind
			bestimmte Cookies technisch zwingend erforderlich, um Ihnen einen Aufruf auf unserer Website zu ermöglichen und
			ohne die eine Nutzung der Spendenplattform nicht möglich ist.
		</p>
		<p>
			Weitere Informationen zu Cookies finden Sie in unseren <a href='/seite/datenschutz'>Datenschutzhinweisen</a>.
		</p>
	</Content>
);

export default CookieBanner;
