import styled from 'styled-components';
import { Image } from 'react-bootstrap';

export const Wrapper = styled.div`
  margin: 20px 0;
  padding: 20px;
  background-color: #cddbe4;
  border-radius: 4px;
`;

export const Avatar = styled(Image)`
  width: 60px;
  height: 60px;
`;

export const Amount = styled.div`
  color: #003064;
  font-size: 28px;
  font-weight: bold;
  text-align: right;
`;
