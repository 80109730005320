import { API_ROUTES } from '../constants/routes';
import { BaseService } from './BaseService';
import StorageUtils from '../utils/StorageUtils';
import { getServerByHost } from '../config';

class AuthService extends BaseService {
  constructor() {
    super(API_ROUTES.root);
  }

  reset = async (credentials) => {
    const { agent } = this;

    try {
      return await agent.post(`/api/0.1${API_ROUTES.reset}`, credentials);
    } catch (err) {
      return err.response;
    }
  };

  resetConfirm = async (credentials) => {
    const { agent } = this;

    try {
      return await agent.post(`/api/0.1${API_ROUTES.resetConfirm}`, credentials);
    } catch (err) {
      return err.response;
    }
  };

  login = async (credentials) => {
    const { agent } = this;
    const authCred = getServerByHost(window.location.hostname).credentials;

    try {
      return await agent.post(`/api/0.1${API_ROUTES.login}`, { ...credentials, ...authCred });
    } catch (err) {
      return err.response;
    }
  };

  register = async (credentials) => {
    const { agent } = this;

    try {
      return await agent.post(`/api/0.1${API_ROUTES.register}`, credentials);
    } catch (err) {
      return err.response;
    }
  };

  regConfirm = async (key) => {
    const { agent } = this;

    try {
      return await agent.get(`/api/0.1${API_ROUTES.regConfirm}/${key}`);
    } catch (err) {
      return err.response;
    }
  };

  profile = async () => {
    const { userAgent } = this;

    userAgent.interceptors.request.use((config) => {
      const token = StorageUtils.restoreToken();

      config.headers.authorization = `Bearer ${token}`;

      return config;
    });

    try {
      const { data } = await userAgent.get(`/api/0.1${API_ROUTES.currentUser}`);

      return data;
    } catch (err) {
      return null;
    }
  };

  updateUser = async (credentials) => {
    const { userAgent } = this;
    const { userId, payload } = credentials;

    try {
      return await userAgent.patch(`/api/0.1${API_ROUTES.register}/uid/${userId}`, payload);
    } catch (err) {
      return err.response;
    }
  };
};

const Service = new AuthService();

export default Service;
export { Service as AuthService };
