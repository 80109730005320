import { useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';

import { BsChevronRight } from 'react-icons/bs';

import { selectUser } from '../../../redux/auth/selectors';

import * as S from './styles';

type Props = {};

const ProfileCard = (props: Props) => {
  const user = useSelector(selectUser);

  const renderLinks = () => (
    user.userLinks.map(l => <li key={l.id}><BsChevronRight /><a href={l.link}>{l.link}</a></li>)
  )

  return (
    <S.StyledContainer>
      <Row>
        <Col sm={4}>
          <S.StyledImage src={ user.avatar || '/person.svg' } roundedCircle  />
        </Col>
        <Col>
          <Row>
            <Col>
              <p className='h5'>
                <strong>Über mich</strong>
              </p>
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col>{user.about}</Col>
          </Row>
          <Row>
            <Col>
              <h5>Links</h5>
              <S.LinksList>
                { user.userLinks && renderLinks() }
              </S.LinksList>
            </Col>
          </Row>
        </Col>
      </Row>
    </S.StyledContainer>
  )
}

export default ProfileCard;
