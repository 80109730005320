import actions from './actions';

export interface IProject {
	project: null|any,
	projects: null|any,
	finishedProjects: null|any,
	loading: null|any,
}

const initState: IProject = {
	project: { projectDonations: [], projectDonationBids: [] },
	projects: { _embedded: { projects: [] } },
	finishedProjects: { _embedded: { projects: [] } },
	loading: { project: false, projects: false },
};

export default function projectReducer(state = initState, { type, payload }) {
	switch (type) {
		case actions.PROJECT_SET: {
			return { ...state, project: payload }
		}
		case actions.PROJECTS_SET: {
			return {
				...state,
				projects: {
					...payload,
					_embedded: { projects: state.projects._embedded.projects.concat(payload._embedded.projects) }
				}
			}
		}
		case actions.FINISHED_PROJECTS_SET: {
			return {
				...state,
				finishedProjects: {
					...payload,
					_embedded: { projects: state.finishedProjects._embedded.projects.concat(payload._embedded.projects) }
				}
			}
		}
		case actions.PROJECTS_LOADING: {
			return { ...state, loading: { ...state.loading, ...payload } }
		}
		default: {
			return state;
		}
	}
}
