import { all, takeLatest, put, call } from 'redux-saga/effects';

import { StaticPagesService } from '../../services/StaticPagesService';
import actions from './actions';

function* loadStaticPage(data) {
  const { payload } = data;
  const response = yield call(StaticPagesService.loadStaticPage, payload);

  if (response?.status === 200) {
    yield put(actions.setStaticPage({ ...response.data, responseStatus: 200 }));
  } else {
    yield put(actions.setStaticPage({ responseStatus: 404 }));
  }
}

function* loadStaticPages() {
  const response = yield call(StaticPagesService.loadStaticPages);

  if (response?.status === 200) {
    yield put(actions.setStaticPages(response.data));
  }
}

function* loadFaq() {
  const response = yield call(StaticPagesService.loadFaq);

  if (response?.status === 200) {
    yield put(actions.setFaq(response.data));
  }
}

export default function* staticPagesSaga() {
  yield all([
    takeLatest(actions.STATIC_PAGE_LOAD, loadStaticPage),
    takeLatest(actions.STATIC_PAGES_LOAD, loadStaticPages),
    takeLatest(actions.FAQ_LOAD, loadFaq),
  ]);
}
