import { createSelector } from 'reselect';
import { IComment } from './reducer';

interface IParams {
  Comment: IComment
}

const comments = ({ Comment }: IParams) => Comment.comments;
const loading = ({ Comment }: IParams) => Comment.loading;

export const selectComments = createSelector([comments], comments => comments);
export const selectLoading = createSelector([loading], loading => loading);
