import { all, takeLatest, put, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { UI_ROUTES } from '../../constants/routes';
import { AuthService } from '../../services/AuthService';
import { StorageUtils } from '../../utils/StorageUtils';
import authActions from '../auth/actions';
import actions from './actions';

function* appStart() {
	yield put(actions.uiLoading({ loading: true }));

	const token = yield call(StorageUtils.restoreToken);
	if (!token) {
		yield put(actions.uiLoading({ loading: false }));
		return;
	}

	const user = yield call(AuthService.profile);

  if (user && user.email) {
    yield put(authActions.profileRefresh(user));
  } else {
    yield call(StorageUtils.clearAll);
		yield put(push(UI_ROUTES.login));
  }
	yield put(actions.uiLoading({ loading: false }));
}

export default function* appSaga() {
	yield all([takeLatest(actions.APP_START, appStart)]);
}
