import { all, takeLatest, put, call } from 'redux-saga/effects';

import { CommentService } from '../../services/CommentService';
import actions from './actions';

function* loadComments(data) {
  const { payload } = data;

  yield put(actions.commentsLoading({ comments: true }));

  const response = yield call(CommentService.loadComments, payload);

  if (response?.status === 200) {
    yield put(actions.setComments(response.data));
  }

  yield put(actions.commentsLoading({ comments: false }));
}

function* createComment(data) {
  const { payload } = data;

  yield put(actions.commentsLoading({ comments: true }));

  const response = yield call(CommentService.createComment, payload);

  if (response?.status === 201) {
    yield put(actions.addComment(response.data));
  }

  yield put(actions.commentsLoading({ comments: false }));
}

export default function* commentSaga() {
  yield all([
    takeLatest(actions.COMMENTS_LOAD, loadComments),
    takeLatest(actions.COMMENT_CREATE, createComment),
  ]);
}
