import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button } from 'react-bootstrap';

import { authActions } from 'redux/auth/actions';
import { selectSignupResponse } from 'redux/auth/selectors';

import TermsLabel from 'components/terms-label';
import { checkError } from 'utils/helpers';

type Props = {};

const initValues = {
  title: '',
  firstName: '',
  lastName: '',
  username: '',
  email: '',
  password: '',
  repeatPassword: '',
  userType: 2
};

const Registration = (props: Props) => {
  const [payload, setPayload] = useState(initValues);
  const dispatch = useDispatch();

  const signupResponse = useSelector(selectSignupResponse);

  const errors = signupResponse?.data?.validation_messages;

  const changeInputHandler = (event) => {
    setPayload({ ...payload, [event.target.name]: event.target.value });
  }

  const onSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    dispatch(authActions.register(payload));
  };

  return (
    <div>
      <Form onSubmit={onSubmit}>
        <Form.Group>
          <Form.Label>Titel</Form.Label>
          <Form.Control isInvalid={errors?.title} type='text' name='title' onChange={changeInputHandler} />
          <Form.Control.Feedback type='invalid'>{errors?.title}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>Vorname *</Form.Label>
          <Form.Control
            isInvalid={errors?.firstName}
            required
            type='text'
            name='firstName'
            onChange={changeInputHandler}
          />
          <Form.Control.Feedback type='invalid'>{errors?.firstName}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>Name *</Form.Label>
          <Form.Control
            isInvalid={errors?.lastName}
            required
            type='text'
            name='lastName'
            onChange={changeInputHandler}
          />
          <Form.Control.Feedback type='invalid'>{errors?.lastName}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>Benutzername *</Form.Label>
          <Form.Control
            isInvalid={errors?.username}
            required
            type='text'
            name='username'
            onChange={changeInputHandler}
          />
          <Form.Control.Feedback type='invalid'>{errors?.username}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>E-Mail-Adresse *</Form.Label>
          <Form.Control isInvalid={errors?.email} required type='email' name='email' onChange={changeInputHandler} />
          <Form.Control.Feedback type='invalid'>
            { checkError(errors?.email, 'email') }
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>Passwort *</Form.Label>
          <Form.Control
            isInvalid={errors?.password}
            required
            type='password'
            name='password'
            onChange={changeInputHandler}
          />
          <Form.Control.Feedback type='invalid'>{errors?.password}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>Passwort wiederholen *</Form.Label>
          <Form.Control
            isInvalid={errors?.repeatPassword}
            required
            type='password'
            name='repeatPassword'
            onChange={changeInputHandler}
          />
          <Form.Control.Feedback type='invalid'>
            { checkError(errors?.repeatPassword, 'repeatPassword') }
          </Form.Control.Feedback>
        </Form.Group>
        <div>
          <a href='/faq' target='_blank'>
            <span className='icon-link-intern' /> Wann ist ein Passwort sicher?
          </a>
        </div>
        <Form.Group>
          <Form.Check required label={<TermsLabel />} />
        </Form.Group>
        <Button variant='primary' type='submit'>
          Registrieren
        </Button>
      </Form>
    </div>
  )
}

export default Registration;
