import { useContext } from 'react';
import { Row, Accordion, Card, useAccordionToggle, AccordionContext } from 'react-bootstrap';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs'

import * as S from '../styles';

type Props = {
  header: string,
  description: null | string,
  eventKey: string,
};

const BlockData = ({ header, description, eventKey }: Props) => {
  const Toggle = ({ children, eventKey, callback }: any) => {
    const currentEventKey = useContext(AccordionContext);

    const decoratedOnClick = useAccordionToggle(
      eventKey,
      () => callback && callback(eventKey),
    );

    const isCurrentEventKey = currentEventKey === eventKey;

    return (
      <span onClick={decoratedOnClick}>{children} { isCurrentEventKey ? <BsChevronUp /> : <BsChevronDown /> }</span>
    );
  }

  return (
    <Row>
      <S.StyledTextBlockCol>
        <S.StyledAccordion defaultActiveKey='0'>
          <Card>
            <Card.Header>
              <Toggle eventKey={eventKey}> {header}</Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey={eventKey}>
              <Card.Body>{description}</Card.Body>
            </Accordion.Collapse>
          </Card>
        </S.StyledAccordion>
      </S.StyledTextBlockCol>
    </Row>
  );
};

export default BlockData;
