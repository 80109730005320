import React from 'react';
import * as PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';
import styled from 'styled-components';

const AbsoluteLayout = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	background-color: white;
`;

const AppLoader = ({ visible }) => {
	if (!visible) {
		return null;
	}

	return (
		<AbsoluteLayout>
			<div className='d-flex justify-content-center align-items-center min-vh-100'>
  	  	<Spinner animation='border' style={{ color: '#D4004B' }} />
  		</div>
		</AbsoluteLayout>
	);
};

AppLoader.propTypes = {
	visible: PropTypes.bool.isRequired,
};

export default AppLoader;
