import styled from 'styled-components';
import { Container, Col, Accordion } from 'react-bootstrap';

export const Wrapper = styled(Container)`
	padding: 10px 0;
  background-color: #2E4A7D;
  background-image: url('/project_bg.svg');
  background-position: right bottom;
  background-repeat: no-repeat;

  @media screen and (max-width: 720px) {
    background-image: none;
  }
`;

export const EmptyContainer = styled(Container)`
	margin: 250px auto;
	padding: 48px;
  background-color: #FFF;
`;

export const PageContentContainer = styled(Container)`
  min-height: 380px;

  @media screen and (max-width: 720px) {
    min-height: 0;
  }
`;

export const WrapperEmpty = styled(Container)`
	padding: 160px 0;
  background-color: #2E4A7D;
  background-image: url('/project_bg.svg');
  background-position: right bottom;
  background-repeat: no-repeat;

  @media screen and (max-width: 720px) {
    background-image: none;
  }
`;

export const PageContent = styled.div`
	font-size: 14px;
	color: black;
	margin-top: 1.25rem;
	line-height: 1.3;
	font-family: 'PT Sans', sans-serif !important;

	img {
		width: 100%;
	}

	span[style*='font-family']{
		font-family: inherit !important;
	}

	h1, h2, h3, h4, h5, h6, h7 {
		color: black;
		text-transform: none;
		line-height: 1.1;
    font-weight: 700;
	}

	h2 {
		font-size: 18px;
	}

	h3 {
		font-size: 14px;
	}

	a {
		color: red
	}

	@media screen and (max-width: 720px) {
		img {
			width: 100% !important;
			height: auto;
		}
	}
`;

export const Title = styled.h1`
	color: #003064;
	font-size: 26px;
	font-weight: 700;
	margin-top: 0;
	margin-bottom: 20px;
	line-height: 1.1;
	text-transform: none;
`;

export const HeaderContainer = styled(Container)`
  padding: 70px 0;
`;

export const Divider = styled.div`
  width: 100%;
  height: 10px;
  background: #E0E2EE;
`;

export const ContentContainerWithBg = styled(Container)`
  padding-top: 35px;
  padding-bottom: 160px;

  background-color: #2E4A7D;

  background-image: url('/project_bg.svg');
  background-position: right bottom;
  background-repeat: no-repeat;

  @media screen and (max-width: 720px) {
    background-image: none;
  }
`;

export const ContentContainer = styled(Container)`
  background-color: #ffffff;
  padding: 30px;

  & h4 {
    padding-bottom: 30px;
    border-bottom: 1px solid rgba(0,0,0,.125);
  }
`;

export const StyledTextBlockCol = styled(Col)`
  padding: 0 15px;
`;

export const StyledAccordion = styled(Accordion)`
  & .card {
    border: 0;
    border-bottom: 1px solid rgba(0,0,0,.125);

    & .card-header {
      border: 0;
      background: none;

      font-style: normal;
      font-weight: bold;
      font-size: 22px;
      line-height: 60px;
      color: #1A1A18;

      padding-left: 0;
      padding-right: 0;
    }

    & .card-body {
      padding-left: 0;
      padding-right: 0;
    }

    & .collapse.show {
      border: 0;
    }
  }
`;
