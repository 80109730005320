import styled from 'styled-components';
import { Container } from 'react-bootstrap';

export const Content = styled.div`
	background-color: white;
	margin: 200px auto;
	padding: 30px 45px;

	@media screen and (max-width: 720px) {
    padding: 30px 45px;
		margin: auto;
  }
`;

export const StyledContainer = styled(Container)`
  background-image: url('/bg-dark.png');
  background-position: left bottom;
  background-repeat: no-repeat;

	padding-top: 20px;
	padding-bottom: 132px;

  @media screen and (max-width: 720px) {
    background-image: none;
		padding: 0 0 132px 0;
  }
`;

export const Wrapper = styled.div`
	background-color: #2E4A7D;
`;
