import styled from 'styled-components';
import { Image, Button } from 'react-bootstrap';

export const Wrapper = styled.div<{ isInitiator?: boolean; }>`
  margin: 20px 0;
  padding: 20px;
  background-color: ${props => props.isInitiator ? '#cddbe4' : '#fff'};
  border-radius: 4px;
  border-bottom: 1px solid #d9e0e8;
`;

export const Avatar = styled(Image)`
  width: 60px;
  height: 60px;
`;

export const Date = styled.span`
  position: relative;
  top: 0;
  right: 0;
  color: #003064;
  font-size: 16px;
  font-weight: bold;
`;

export const NameWrapper = styled.div`
  a {
    text-decoration: none !important;
    color: #000 !important;
    font-weight: unset;
  }
`;

export const About = styled.div`
  line-height: 12px;
  font-weight: 400 !important;
`;

export const CommentWrapper = styled.span`
  margin-top: 20px;
  font-size: 14px;
  color: #000;
  margin-bottom: 30px;
`;

export const ReplyButton = styled.div`
  background-color: #003064;
  color: #fff;
  border-radius: 2px;
  border: 1px solid transparent;
  font-weight: bold;
  font-size: 14px;
  padding: 5px 15px;
  text-align: center;
  vertical-align: middle;

  &:hover {
    background-color: #D4004B;
    cursor: pointer;
  }

  @media screen and (min-width: 720px) {
    width: 50%;
  }
`;

export const Title = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 700;
`;

export const UnregisteredWrapper = styled.div`
  line-height: 1.3em;
  margin-bottom: 30px;

  a {
    text-decoration: none !important;
    font-weight: unset;
  }
`;

export const SubmitButton = styled(Button)`
  background-color: #003064 !important;
  color: #fff;
  border-radius: 2px !important;
  border: 1px solid transparent;
  font-weight: bold;
  font-size: 14px;
  padding: 5px 15px;
  text-align: center;
  vertical-align: middle;
  width: 100% !important;

  &:hover:enabled {
    background-color: #D4004B !important;
    cursor: pointer;
  }
`;

export const FormWrapper = styled.div`
  margin-bottom: 50px;

  textarea {
    resize: none;
    border: none;
    border-radius: 4px !important;
    background-color: #cddbe4;
    box-shadow: none;
    font-size: 16px;
    margin-bottom: 30px;
  }
`;

export const CancelButton = styled.div`
  text-align: end;
  font-weight: 700;
  color: #064a78;
  font-size: 14px;
  line-height: 30px;

  &:hover {
    cursor: pointer;
  }
`;

export const HideButton = styled.a`
  text-decoration-line: none;
  font-weight: normal;
  font-size: 14px;

  &:hover {
    cursor: pointer;
    text-decoration-line: none;
  }
`;

export const UserDataWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  @media screen and (max-width: 720px) {
    flex-direction: column;
  }
`;

export const DeletedCommentWrapper = styled.div`
  background-color: #EFE5E5;
  position: relative;
  border-bottom: 1px solid #d9e0e8;
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 30px;
  font-size: 14px;
  color: #000;
`;
