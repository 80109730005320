import { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form, Button, Alert } from 'react-bootstrap';
import { BsPlusSquareFill, BsXSquareFill } from 'react-icons/bs';
import { HiddenCropper } from 'react-bootstrap-image-cropper';

import { authActions } from '../../../redux/auth/actions';
import { selectUser, selectUpdateUserResponse, selectAlertForUpdate } from '../../../redux/auth/selectors';

import { checkError } from '../../../utils/helpers'

import * as S from './styles';

type Props = {};

const ProfileForm = (props: Props) => {
  const user = useSelector(selectUser);
  const updateUserResponse = useSelector(selectUpdateUserResponse);
  const { isVisible } = useSelector(selectAlertForUpdate);

  const initValues = {
    title: user.title,
    firstName: user.firstname,
    lastName: user.lastname,
    username: user.username,
    about: user.about,
    city: user.city,
    avatar: user.avatar || '',
    userLinks: user.userLinks,
  };

  const [payload, setPayload] = useState(initValues);
  const [tempLink, setTempLink] = useState('');
  const dispatch = useDispatch();
  const fileRef = useRef<any>(null);

  const errors = updateUserResponse?.data?.validation_messages;

  const changeInputHandler = (event) => {
    setPayload({ ...payload, [event.target.name]: event.target.value });
  }

  const fileSelectHandler = (blob) => {
    const file = new File([blob], 'avatar.jpeg');
    setPayload({ ...payload, avatar: file })
  }

  const linkAddHandler = (event) => setTempLink(event.target.value);

  const onSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const formData = new FormData();

    for (const [key, value] of Object.entries(payload)) {
      if (key === 'avatar' && value === user.avatar) continue;
      if (key === 'userLinks') {
        if (value.length)
          value.forEach(item => formData.append('userLinks[]', item.link))
        else
          formData.append('userLinks', '')
      } else {
        formData.append(key, value);
      }
    }

    const credentials = { payload: formData, userId: user.uid }

    dispatch(authActions.updateUser(credentials));
  };

  const handleCloseAlert = () => dispatch(authActions.alertForUpdate({ isVisible: false }));
  const handleSelectImage = () => fileRef?.current?.trigger();
  const handleRemoveAvatar = () => setPayload({ ...payload, avatar: '' });

  const handleRemoveLink = (id, tempId) => {
    const links = payload.userLinks.filter(item => id ? (item.id !== id) : (item.tempId !== tempId))

    setPayload({ ...payload, userLinks: links });
  }

  const handleAddLink = () => {
    if (tempLink.trim() === '') return

    const links = payload.userLinks.concat([{ tempId: Date.now(), link: tempLink }])

    setPayload({ ...payload, userLinks: links });
    setTempLink('')
  }

  const getAvatar = () => {
    if (!payload.avatar) return '/person.svg';

    return (typeof payload.avatar === 'string') ? payload.avatar : URL.createObjectURL(payload.avatar);
  }

  const renderLinks = () => (
    payload.userLinks.map(l =>
      <Row key={l.id || l.tempId} className='py-2'>
        <Col>
          <span>{l.link}</span>
        </Col>
        <Col>
          <S.RemoveItemWrapper
            className='d-flex flex-row align-items-center text-nowrap'
            onClick={() => handleRemoveLink(l.id, l.tempId)}
          >
            <BsXSquareFill size={32} className='mr-2' /> Link löschen
          </S.RemoveItemWrapper>
        </Col>
      </Row>
    )
  )

  return (
    <S.StyledContainer>
      <Row><Col><h3>Profil bearbeiten</h3></Col></Row>
      <Row className='mb-5'><Col>Bitte füllen Sie alle mit einem * markierten Felder aus.</Col></Row>
      <Alert variant='success' show={isVisible} onClose={handleCloseAlert} dismissible >
        Änderungen wurden erfolgreich gespeichert
      </Alert>
      <Row>
        <Col sm={12} xs={12}>
          <Form onSubmit={onSubmit}>
            <Form.Group>
              <Form.Label>Titel</Form.Label>
              <Form.Control
                type='text'
                name='title'
                isInvalid={errors?.title}
                defaultValue={payload.title}
                onChange={changeInputHandler}
              />
              <Form.Control.Feedback type='invalid'>{errors?.title}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Vorname *</Form.Label>
              <Form.Control
                type='text'
                name='firstName'
                required
                isInvalid={errors?.firstName}
                defaultValue={payload.firstName}
                onChange={changeInputHandler}
              />
              <Form.Control.Feedback type='invalid'>{errors?.firstName}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Name *</Form.Label>
              <Form.Control
                type='text'
                name='lastName'
                required
                isInvalid={errors?.lastName}
                defaultValue={payload.lastName}
                onChange={changeInputHandler}
              />
              <Form.Control.Feedback type='invalid'>{errors?.lastName}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Benutzername *</Form.Label>
              <Form.Control
                type='text'
                name='username'
                required
                isInvalid={errors?.username}
                defaultValue={payload.username}
                onChange={changeInputHandler}
              />
              <Form.Control.Feedback type='invalid'>{errors?.username}</Form.Control.Feedback>
            </Form.Group>
            <h5>Passwort</h5>
            <Form.Group>
              <Form.Label>Aktuelles Passwort</Form.Label>
              <Form.Control
                type='password'
                name='currentPassword'
                isInvalid={errors?.currentPassword}
                onChange={changeInputHandler}
              />
              <Form.Control.Feedback type='invalid'>{errors?.currentPassword}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Neues Passwort</Form.Label>
              <Form.Control
                type='password'
                name='password'
                isInvalid={errors?.password}
                onChange={changeInputHandler}
              />
              <Form.Control.Feedback type='invalid'>{errors?.password}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Neues Passwort wiederholen</Form.Label>
              <Form.Control
                type='password'
                name='repeatPassword'
                isInvalid={errors?.repeatPassword}
                onChange={changeInputHandler}
              />
              <Form.Control.Feedback type='invalid'>
                { checkError(errors?.repeatPassword, 'repeatPassword') }
              </Form.Control.Feedback>
            </Form.Group>
            <h5>Über Sie</h5>
            <div className='mb-5'>
              Wer sind Sie? Was bewegt Sie dazu, sich für eine gemeinnützige Sache einzusetzen?
            </div>
            <Row className='mb-5'>
              <Col md={5} sm={12}>
                <div className='mr-3' ><S.StyledImage src={getAvatar()} rounded  /></div>
              </Col>
              <Col md={7} sm={12}>
                <div className='d-flex align-items-start flex-column'>
                  <S.AddItemWrapper className='d-flex flex-row align-items-center mb-3' onClick={handleSelectImage}>
                    <BsPlusSquareFill size={32} className='mr-2' /> Bild hochladen
                  </S.AddItemWrapper>
                  <S.RemoveItemWrapper className='d-flex flex-row align-items-center mb-3' onClick={handleRemoveAvatar}>
                    <BsXSquareFill size={32} className='mr-2' /> Bild löschen
                  </S.RemoveItemWrapper>
                  <S.AvatarDescriptionWrapper className='mt-auto'>
                    Möglich sind die Formate JPEG und PNG bis zu einer Größe von 5 MB
                  </S.AvatarDescriptionWrapper>
                </div>
              </Col>
            </Row>
            <Form.Group>
              <HiddenCropper
                triggerRef={fileRef}
                onCropped={fileSelectHandler}
                cropOptions={{ aspect: 1 / 1, maxZoom: 10 }}
                outputOptions={{ maxWidth: 280, maxHeight: 280 }}
                previewOptions={{ width: 280, height: 280 }}
              />
              <Form.Control
                type='file'
                className='d-none'
                isInvalid={errors?.avatar}
              />
              <Form.Control.Feedback type='invalid'>
                { errors?.avatar && Object.values(errors?.avatar).join(', ') }
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Kurzbeschreibung</Form.Label>
              <Form.Control
                type='text'
                name='about'
                isInvalid={errors?.about}
                defaultValue={payload.about}
                onChange={changeInputHandler}
              />
              <Form.Control.Feedback type='invalid'>{errors?.about}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Wohnort</Form.Label>
              <Form.Control
                type='text'
                name='city'
                isInvalid={errors?.city}
                defaultValue={payload.city}
                onChange={changeInputHandler}
              />
              <Form.Control.Feedback type='invalid'>{errors?.city}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Weblinks</Form.Label>
              { payload.userLinks && renderLinks() }
              <Row>
                <Col xs={12} sm={8}>
                  <Form.Control
                    type='text'
                    name='userLink'
                    className='mr-2'
                    onChange={linkAddHandler}
                    value={tempLink}
                  />
                </Col>
                <Col>
                  <S.AddItemWrapper
                    className='d-flex flex-row align-items-center text-nowrap'
                    onClick={handleAddLink}
                  >
                    <BsPlusSquareFill size={32} className='mr-2' /> Link hinzufügen
                  </S.AddItemWrapper>
                </Col>
              </Row>
            </Form.Group>
            <div className='mt-5 mb-5'>
              Die mit einem Stern * gekennzeichneten Felder sind Pflichtfelder und müssen ausgefüllt werden.
            </div>
            <Button variant='primary' type='submit' className='btn-block'>
              Änderungen speichern
            </Button>
          </Form>
        </Col>
      </Row>
    </S.StyledContainer>
  )
}

export default ProfileForm;
