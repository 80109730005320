import styled from 'styled-components';

import { Card, Container } from 'react-bootstrap';

export const StyledContainer = styled(Container)`
  padding: 115px 0;

  @media (max-width: 992px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

export const StyledCard = styled(Card)`
  min-height: 600px;
  border-radius: 0;

  .card-img-top {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
`;

export const Description = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #515151;
`;

export const Link = styled.div`
  position: absolute;
  width: 100%;
  height: 60px;
  right: 0;
  bottom: 0;

  font-size: 18px;
  line-height: 60px;

  background-image: linear-gradient(to bottom right, transparent 49%, #003064 50%);

  > a {
    position: absolute;
    right: 20px;
    bottom: -8px;
    text-decoration: none;
    font-weight: normal;

    &:link {
      color: #FFFFFF !important;
    }

    &:visited {
      color: #FFFFFF !important;
    }
  }
`;

export const TitleLink = styled.a`
  text-decoration-line: none;

  &:hover {
    text-decoration-line: underline;
  }
`;
