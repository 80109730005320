import React from 'react';
import ReactDOM from 'react-dom';

import App from './app';
// import ScrollToTop from './app/scroll-to-top';
import reportWebVitals from './reportWebVitals';

import './index.css'; // this is the default styles that came with create-react-app
import 'bootstrap/dist/css/bootstrap.min.css';

import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import { store, history } from './redux/store';

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      {/* <ScrollToTop /> */}
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
