import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 250px;
  position: relative;
  padding: 30px 25px 30px;
`;

export const Title = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  color: #003064;
  margin-bottom: 20px;
`;

export const Stats = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 55px;
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ItemDesc = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #1A1A18;
  margin-bottom: 2px;
`;

export const PendingSum = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #1A1A18;
  margin-left: 3px;
`;

export const ItemValue = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #003064;
  margin-bottom: 2px;
`;

export const Links = styled.div`
  position: absolute;
  height: 55px;
  left: 0;
  right: 0;
  bottom: 0;

  font-size: 18px;
  line-height: 60px;

  background-image: linear-gradient(to bottom right, transparent 49%, #003064 50%);

  > span {
    position: absolute;
    right: 20px;
    bottom: -8px;
    cursor: pointer;
  }

  & a {
    color: #FFFFFF !important;
    text-decoration-line: none;
    font-weight: normal;
  }
`;

export const LinkWrapper = styled.div`
  > a {
    text-decoration-line: none;

    & :hover {
      text-decoration-line: underline;
    }
  }
`;
