import styled from 'styled-components';
import { Container, Row, Col, Accordion } from 'react-bootstrap';

export const StyledContainer = styled(Container)`
  background-color: #2E4A7D;

  padding: 50px 0;

  background-image: url('/project_bg.svg');
  background-position: right bottom;
  background-repeat: no-repeat;

  @media screen and (max-width: 720px) {
    background-image: none;
  }
`;

export const StyledProjectCol = styled(Col)`
  background-color: #ffffff;
`;

export const StyledTabRow = styled(Row)`
  padding-top: 20px;
  padding-bottom: 20px;
`;

export const StyledImagesRow = styled(Row)`
  padding: 0;

  > div {
    padding: 0;
  }

  & .image-gallery-thumbnails-container {
    padding-top: 30px;
    padding-left: 30px;

    text-align: left;
  }

  & .image-gallery-right-nav, .image-gallery-left-nav {
    border-radius: 50%;
    background-color: rgb(255, 255, 255, 0.7);
    padding: 0 9px;
  }

  & .image-gallery-left-nav {
    margin-left: 15px;
  }

  & .image-gallery-right-nav {
    margin-right: 15px;
  }

  & .image-gallery-right-nav svg {
    height: 40px;
    width: 20px;
    color: #003064;
  }

  & .image-gallery-left-nav svg {
    height: 40px;
    width: 20px;
    color: #003064;
  }
`;

export const StyledTextBlockCol = styled(Col)`
  padding: 0 30px;
`;

export const StyledAccordion = styled(Accordion)`
  & .card {
    border: 0;
    border-bottom: 1px solid rgba(0,0,0,.125);

    & .card-header {
      border: 0;
      background: none;

      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 1.1;
      color: #1A1A18;

      padding-left: 0;
      padding-right: 0;
    }

    & .card-body {
      padding: 0 0 10px 0;
      font-size: 16px;
      line-height: 1.3em;
    }

    & .collapse.show {
      border: 0;
    }
  }
`;

export const TabButton = styled.div`
  text-align: center;

  background: #ffffff;

  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;

  color: #2E4A7D;

  padding: 8px;

  border: 1px solid #455B8C;
  box-sizing: border-box;
  border-radius: 2px;

  &:not(.active):hover {
    cursor: pointer;
  }

  &.active, &:hover {
    background: #003064;
    color: #ffffff;
  }
`;

export const SupportButton = styled.button`
  width: 40%;
  min-width: 230px;
  background: #D4004B;
  border: 0;
  border-radius: 3px;
  padding: 15px;

  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  text-align: center;

  color: #FFFFFF;
`;

export const BlockHeader = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 40px;
  color: #1A1A18;
`;

export const ProjectLink = styled.a`
  font-size: 14px;
  text-decoration-line: none;

  &:hover {
    text-decoration-line: none;
  }
`;

export const ProjectLinkRow = styled(Row)`
  padding: 30px 0;
`;

export const SocialsRow = styled(Row)`
  border: 1px solid #E0E2EE;
  border-left: 0;
  border-right: 0;

  padding: 30px;

  > div {
    padding: 0;
  }
`;

export const SupportRow = styled(Row)`
  padding: 20px 0;
`;

export const DonateBlock = styled.div`
  background-color: #003064;
  padding: 15px;
  margin: 0 -15px 20px 5px;

  & a {
    text-decoration: none;
  }

  & .form-group {
    position: relative;

    & input {
      font-size: 18px;
      line-height: 18px;
      padding-right: 50px;
    }

    & .amount {
      display: inline-block;
      position: absolute;
      top: 1px;
      right: 10px;
      color: #000;
      font-size: 18px;
      font-weight: 700;
      margin-left: -8px;
      margin-top: 7px;
    }
  }

  @media screen and (max-width: 575px) {
    margin: 20px -15px;
  }
`;

export const DonateHeader = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 1em;
  text-transform: uppercase;

  color: #FFFFFF;

  margin: 12px 0 24px;
`;

export const DonateReceipt = styled.div`
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;

  & span {
    font-size: 35px;
    margin-right: 5px;
    position: relative;
    top: 7px;
  }
`;

export const InfoBlock = styled.div`
  padding: 20px;
  background: #FFFFFF;

  margin-bottom: 20px;
`;

export const InfoBlockHeader = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 34px;
  color: #003064;
`;

export const Badge = styled.span`
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-weight: bold;
  line-height: 1;
  vertical-align: baseline;
  white-space: nowrap;
  text-align: center;
  background-color: #D4004B;
  border-radius: 0;
  color: #fff;
  font-size: 14px;
`;

export const MailToLink = styled.a`
  text-decoration: none;
  font-weight: 700;
  font-size: 14px;
  color: #fff !important;
`;
