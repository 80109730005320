import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '../../../redux/auth/actions';
import { selectLoginResponse, selectResetPasswordResponse } from '../../../redux/auth/selectors';

import { Form, Button, Modal } from 'react-bootstrap';
import * as S from './styles';

type Props = {};

const initValues = {
  username: '',
  password: '',
  grant_type: 'password'
};

const Login = (props: Props) => {
  const [showResetModal, setShowResetModal] = useState(false);
  const [payload, setPayload] = useState(initValues);
  const [email, setResetEmail] = useState('');
  const loginResponse = useSelector(selectLoginResponse);
  const resetPasswordResponse = useSelector(selectResetPasswordResponse);

  const dispatch = useDispatch();

  const hasError = loginResponse?.status === 401;
  const resetError = resetPasswordResponse?.data?.detail;

  const handleClose = () => setShowResetModal(false);
  const handleShow = () => setShowResetModal(true);
  const handleResetPassword = () => dispatch(authActions.resetpassword({ email }));

  const changeInputHandler = (event) => {
    setPayload({ ...payload, [event.target.name]: event.target.value });
  }

  const changeResetPasswordInputHandler = (event) => {
    setResetEmail(event.target.value);
  }

  const onSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    dispatch(authActions.login(payload));
  };

  return (
    <S.Wrapper className='d-flex flex-column'>
      <Form onSubmit={onSubmit}>
        <Form.Group>
          <Form.Label>E-Mail-Adresse *</Form.Label>
          <Form.Control isInvalid={hasError} required type='email' name='username' onChange={changeInputHandler} />
        </Form.Group>
        <Form.Group>
          <Form.Label>Passwort *</Form.Label>
          <Form.Control isInvalid={hasError} required type='password' name='password' onChange={changeInputHandler} />
          { hasError && <Form.Control.Feedback type='invalid'>E-Mail-Adresse oder Passwort nicht korrekt.</Form.Control.Feedback> }
        </Form.Group>
        <Form.Group>
          <Form.Check type='checkbox' label='Eingeloggt bleiben' />
        </Form.Group>
        <Button variant='primary' type='submit'>
          Einloggen
        </Button>
      </Form>
      <S.PasswordForgotten onClick={handleShow}>
        <a href='#'><span className='icon-link-intern' /> Passwort vergessen?</a>
      </S.PasswordForgotten>

      <Modal show={showResetModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Passwort zurücksetzen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>Bitte geben Sie Ihre E-Mail-Adresse ein. Wir senden Ihnen einen Bestätigungslink, mit dem Sie Ihr Passwort ändern können.</div>
          <Form>
            <Form.Group>
              <Form.Label>E-Mail:</Form.Label>
              <Form.Control
                isInvalid={resetError}
                required
                type='email'
                name='email'
                onChange={changeResetPasswordInputHandler}
              />
              <Form.Control.Feedback type='invalid'>{resetError}</Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='primary' onClick={handleResetPassword} className='btn-login'>
            Bestätigungslink senden
          </Button>
        </Modal.Footer>
      </Modal>
    </S.Wrapper>
  )
}

export default Login;
