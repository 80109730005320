export const ERRORS = {
  repeatPassword: {
    eng: 'Password confirmation not match',
    ger: 'Die beiden angegebenen Passwörter stimmen nicht überein.'
  },
  email: {
    eng: 'Email address has been exist, please find another email address',
    ger: 'Diese E-Mail-Adresse wird bereits verwendet.'
  }
}
