import { all, takeLatest, put, call } from 'redux-saga/effects';

import { SupporterService } from '../../services/SupporterService';
import actions from './actions';

function* loadSupporters(data) {
  const { payload } = data;
  const response = yield call(SupporterService.loadSupporters, payload);

  if (response?.status === 200) {
    yield put(actions.setSupporters(response.data));
  }
}

export default function* supportersSaga() {
  yield all([
    takeLatest(actions.SUPPORTERS_LOAD, loadSupporters),
  ]);
}
